// This file/class uses Babel + "ES6"

class saBroadcasterSignup{
    constructor(settings){
        this.settings = settings;
		this.currentPage = 1;
		this.pageCount = 6;
		this.form = $('form');
		this.submitButton = $('.footer-buttons .continue');
        this.cancelButton = $('.footer-buttons .back');
		this.form.on('submit', (event) => {
			event.preventDefault();
			if(this.pageIsValid(this.currentPage)){
				this.submitPage();
			}
		})
		.areYouSure();
		$('.form-pager .page').saClick((index, element) => this.goToPage($(element).data('page')))
		$('.back').saClick(() => this.pageBack());
		this.setupFormValidation();
		this.setupSpeakerSelector();
		this.updatePage(true);
	}
	get couponId(){
		return this.settings.couponId != '' ? this.settings.couponId : undefined;
	}
	get repId(){
		return this.settings.repId != '' ? this.settings.repId : undefined;
	}
	get requiresPayment(){
		return !this.settings.skipCC;
	}
	get broadcasterIdIsValid(){
		return $('.broadcaster-id-input').hasClass('valid');
	}
	get broadcasterId(){
		return $('.broadcaster-id-input input').val();
	}
	get emailAddress(){
		return $('#broadcaster-main-email').val();
	}
	get articlesOfFaith(){
		return $('#articles-of-faith-checkbox').prop('checked');
	}
	get phoneNumber(){
		return $('#phone-number').val();
	}
	get broadcasterName(){
		return $('#organization-name').val();
	}
	get location(){
		return $('#general-location').val();
	}
	get address(){
		return $('#physical-address').val();
	}
	get countryCode(){
		return sa.dropdownValue('country');
	}
	get usaSelected(){
		return this.countryCode == 'US';
	}
	get zipCode(){
		return this.usaSelected ? $('#zipcode').val() : undefined;
	}
	get survey(){
		return $('#discovery-survey').val();
	}
	get isChurch(){
		return $('#is-church-checkbox').prop('checked');
	}
	get isSeminary(){
		return $('#is-seminary-checkbox').prop('checked');
	}
	get isRadio(){
		return $('#is-radio-checkbox').prop('checked');
	}
	get isSchool(){
		return $('#is-school-checkbox').prop('checked');
	}
	get websiteUrl(){
		return $('#website-url').val();
	}
	get password(){
		return $('#broadcaster-password').val();
	}
	get confirmPassword(){
		return $('#broadcaster-password-confirm').val();
	}
	get matchingPasswords(){
		return this.confirmPassword == this.password;
	}
	checkArticlesOfFaith(){
		$('.articles-of-faith-requirement-message').toggleClass('hidden', this.articlesOfFaith);
	}
	checkMatchingPasswords(){
		$('.password-match-message').toggleClass('hidden', this.confirmPassword == '' || this.matchingPasswords);
	}
	setupSpeakerSelector(){
		this.primarySpeaker = '';
		this.speakerSelector = new saSpeakerSelector({
			container_id: 'primary-speaker',
			on_selection: (val, imgHTML) => {
				this.primarySpeaker = val;
			},
		});
	}
	setupPayment(){
		this.paymentMethod = new saPaymentMethod({
			createCustomer: {
				email: this.emailAddress,
				name: this.broadcasterName,
				id: this.broadcasterId,
			},
            onCreateError: (message) => {
				this.enableSubmit();
			},
            // onEnable: () => { },
			onCustomerCreated: (id) => {
				this.cardCustomerId = id;
			},
            storePaymentMethod: (options) => {
                this.cardInfo = options
				this.submitForm();
            },
        });
	}
	pageIsValid(page){
		var valid = true;
		if(page == 1){
			this.checkArticlesOfFaith();
			this.checkMatchingPasswords();
			if(this.emailAddress == '') valid = false;
			if(!this.broadcasterIdIsValid) valid = false;
			if(!this.matchingPasswords) valid = false;
			if(!this.articlesOfFaith) valid = false;
		}
		if(page == 2){
			if(this.broadcasterName == '') valid = false;
		}
		if(page == 3){
			if(this.location == '') valid = false;
			if(this.address == '') valid = false;
			if(this.usaSelected && this.zipCode == '') valid = false;
		}
		if(page == 4){
			if(!this.billingEmail || this.billingEmail == '') valid = false;
			if(!this.encodingEmail || this.encodingEmail == '') valid = false;
		}
		return valid;
	}
	get formIsValid(){
		if(!this.broadcasterIdIsValid) return false;
		return true;
	}
	get billingEmail(){
		var val = $('.billing-email:checked').val();
		return val == 'default' ? this.emailAddress : $('#billing-email-custom-input').val();
	}
	get encodingEmail(){
		var val = $('.encoding-email:checked').val();
		return val == 'default' ? this.emailAddress : $('#encoding-email-custom-input').val();
	}
	setupFormValidation(){
		$('input[type="password"]').removeAttr('readonly');
		$('.broadcaster-id-input input').on('input', () => this.validateBroadcasterId());
		$('input[name="password"]').on('input', () => this.checkMatchingPasswords());
		$('#articles-of-faith-checkbox').saCheckbox( () => this.checkArticlesOfFaith());
		$('#country').on('input', () => {
			$('.zipcode').toggleClass('hidden', !this.usaSelected);
		});
		$('#broadcaster-main-email').on('input', () => {
			$('.default-email-address').text(this.emailAddress);
		});
		$('.email-radio').on('input', (event) => {
			var input = $(event.currentTarget);
			var group = input.attr('name');
			var checked = $('.' + group + ':checked').val();
			var showCustom = checked != 'default';
			$('#' + group + '-custom-input').toggleClass('hidden').prop('required', showCustom);
		});
	}
	validateBroadcasterId(){
		$('.broadcaster-id-input').addClass('validating').removeClass('valid invalid');
		clearTimeout(this.validateBroadcasterIdTimeout);
		var val = $('.broadcaster-id-input input').val();
		$('.broadcaster-id-input input').val(val.toLowerCase());
		var message = '';
		var empty = val == '';
		var invalidLength = val.length < 3 || val.length > 20;
		var containsInvalidCharacters = !val.match(/^[a-z0-9]+$/i);
		var invalid = empty || invalidLength || containsInvalidCharacters;
		if(invalid){
			if(invalidLength) message = interpolate("Broadcaster ID must be %s-%s characters in length.", [3, 20]);
			if(containsInvalidCharacters) message = gettext("Broadcaster ID can only contain numbers and lower case letters.");
			if(empty) message = '';
			this.setBroadcasterIdValid(false, message);
			return;
		}
		this.validateBroadcasterIdTimeout = setTimeout(() => {
			$.ajax({
				type: 'GET',
				url: this.settings.broadcaster_id_validation_url,
				data: {id: val},
				success: (data) => {
					this.setBroadcasterIdValid(true, message);
				},
				error: (data) => {
					this.setBroadcasterIdValid(false, data.responseJSON);
					console.error(data);
				},
			});
		}, 500);
	}
	setBroadcasterIdValid(valid, message){
		$('.broadcaster-id-input').removeClass('validating')
		.toggleClass('valid', valid).toggleClass('invalid', !valid);
		$('.broadcaster-id-input .message').html(message);
	}
	goToPage(page){
		if(this.currentPage == page) return;
		if(page > this.currentPage){
			for(var i = 1; i < page; i++){
				if(!this.pageIsValid(i)){
					page = i;
					this.submitButton.trigger('click');
				}
			}
		}
		this.currentPage = page;
		this.updatePage();
	}
	pageBack(){
		if(this.currentPage == 1) return;
		this.currentPage -= 1;
		this.updatePage();
	}
	submitPage(){
		if(this.currentPage == this.pageCount){
			this.submitButton.disable().addClass('processing');
		    this.cancelButton.disable();
			if(this.requiresPayment && !this.cardInfo){
				this.paymentMethod.createPaymentMethod();
			} else {
				this.submitForm();
			}
			return;
		}
		this.currentPage += 1;
		this.updatePage();
	}
	scrollUp(){
		sa.utils.scroll.to($('.page-header.active'), sa.utils.scroll.topPadding, sa.utils.scroll.defaultSpeed / 4);
	}
	updatePage(skipScroll){
		$('.page.active').removeClass('active');
		$('.page-' + this.currentPage).addClass('active');
		for(var i = 0; i < this.pageCount; i++){
			var page = i + 1;
			var fields = $('.page-' + page).find('input, textarea');
			fields.toggleDisability(page == this.currentPage);
		}
		if(this.currentPage == this.pageCount){
			if(this.requiresPayment){
				this.setupPayment();
			}
		}
		if(!skipScroll){
			this.scrollUp();
		}
	}
	submitForm(){
		var data = {
		    'id': this.broadcasterId,
		    'password': this.password,
		    'name': this.broadcasterName,
		    'location': this.location,
		    'country': this.countryCode,
		    'address': this.address,
		    'zip': this.zipCode,
		    'phone': this.phoneNumber,
		    'speaker': this.primarySpeaker,
		    'public_email': this.emailAddress,
			'encoding_email': this.encodingEmail,
			'billing_email': this.billingEmail,
		    'is_church': this.isChurch,
		    'is_school': this.isSchool,
		    'is_seminary': this.isSeminary,
		    'is_radio': this.isRadio,
			'coupon_id': this.couponId,
			'rep_id': this.repId,
		    'website': this.websiteUrl,
		    'survey': this.survey,
		    'customer_id': this.cardCustomerId,
		    ...this.cardInfo
		};
		sa.setupCSRF();
		$.ajax({
			type: 'POST',
			url: this.settings.broadcaster_signup_submission_url,
			data: data,
			success: (resp) => {
				sa.toasts.toast(gettext('Signup completed successfully!'));
				this.signIn();
			},
			error: (resp) => {
				console.error(resp);
				this.enableSubmit();
				sa.toasts.toast(gettext('Something went wrong while submitting this form. Please try again.'), sa.toasts.types.error, resp);
			},
		});
	}
	enableSubmit(){
		this.submitButton.enable().removeClass('processing');
		this.cancelButton.enable();
	}
	signIn(){
		$.ajax({
			url: this.settings.sign_in_url,
			type: 'POST',
			data: {
				'csrfmiddlewaretoken': $('[name="csrfmiddlewaretoken"]').val(),
				'username': this.broadcasterId,
				'password': this.password,
			},
			success: () => {
				window.location.href = this.settings.welcome_url;
			},
			error:(error) => {
				console.error(error);
				sa.toasts.toast(gettext('Something went wrong while trying to sign in.'), sa.toasts.types.error, error);
			}
		});
	}
}
