
(function() {
    this.saReportFeedback = function(settings) {
    	var _this = this;
    	this.init = function () {
            var patt = new RegExp('@');
            $('#report_feedback').on('submit',function (e){
                e.preventDefault();
                var comment = $('#report_feedback_comments').val();
                var email = $('#report_feedback_email').val();
                var name = $('#report_feedback_name').val();
                var emailRegex = patt.test(email);
                var longEnough = comment.length >= sa.site.feedback.minCommentCharacters;
                var hasName = name.length > 0;
                if(longEnough && emailRegex && hasName){
                    sa.site.feedback.submit({
                        name: name,
                        email: email,
                        body: comment,
                        phone: $('#report_feedback_phone').val(),
                        church: settings.church,
                        sourceid: settings.sourceid,
                        referringurl: settings.referringurl,
                    }, function(event){
                        //success
                        $('#report_feedback_msg').addClass('success');
                        $('#report_feedback_msg').html('<p>' + gettext('Your feedback has been sent.') + '</p>');
                        $('#report_feedback input[type=submit]').val(gettext('Thanks!'));
                        $("#report_feedback input, #report_feedback textarea").prop('disabled', true);
                    }, function(event){
                        //error
                        $('#report_feedback_msg').addClass('error');
                        $('#report_feedback_msg').html('<p>' + gettext('Something went wrong. Please try again.') + '</p>');
                    });
                } else {
                    $('#report_feedback_msg').addClass('error');
                    var string, translatedString;
                    if(!hasName){
                        string = gettext('Please provide your name.');
                    } else if(!emailRegex){
                        string = gettext('Please provide an email address.');
                    } else {
                        translatedString = ngettext('Please include a comment that is at least %s character.', 'Please include a comment that is at least %s characters.', sa.site.feedback.minCommentCharacters);
                        string = interpolate(translatedString,[sa.site.feedback.minCommentCharacters]);
                    }
                    $('#report_feedback_msg').html('<p>' + string + '</p>');
                }
            });
		};
        _this.init();
	};
}());
