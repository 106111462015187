// This file/class uses Babel + "ES6"

class saImageEditor{
    constructor(settings){
        this.settings = settings;
        this.idealWidth = settings.idealWidth;
        this.idealHeight = settings.idealHeight;
        this.minWidth = settings.minWidth || 1400;
        this.aspectRatio = settings.minHeight ? settings.minWidth / settings.minHeight : 1;
        this.setAspectRatio(this.aspectRatio)
        this.minHeight = settings.minHeight || this.minWidth * this.aspectRatio;
        this.pollSpeed = 10; // seconds
        this.$preview = this.get('.preview');
        this.$image = this.get('img');
        // https://github.com/fengyuanchen/cropperjs/blob/master/README.md
        this.$image.cropper({
            aspectRatio: this.aspectRatio,
            autoCropArea: 1,
            dragMode: 'move',
            viewMode: 1, // https://github.com/fengyuanchen/cropperjs/blob/main/README.md#viewmode
            responsive: true,
            crop: (event) => { this.onCrop(event) },
            cropstart: (event) => { this.onCropStart(event) },
            cropend: (event) => { this.onCropEnd(event) },
            ready: (event) => { this.onReady(event) },
            preview: this.$preview,
        });
        this.get('.shapes').toggleClass('hidden', !this.settings.shapes);
        if(this.settings.shapes){
            this.get('.shapes > div').saClick((index, element) => {
                this.get('.preview-container').removeClass('square-type circle-type').addClass($(element).data('shape') + '-type');
            });
        }
        this.get('.reset').saClick(() => { this.reset() });
        this.get('.flip-x').saClick(() => { this.flipX() });
        this.get('.flip-y').saClick(() => { this.flipY() });
        this.get('.rotate-cw').saClick(() => { this.rotateCW() });
        this.get('.rotate-ccw').saClick(() => { this.rotateCCW() });

        this.$uploadLabel = $('#' + this.settings.uploadInputId + '-container');
        this.$uploadLabel[0].addEventListener('dragover', (event) => {
            this.dragOverInput(event);
        }, false);
        this.$uploadLabel[0].addEventListener('drop', (event) => {
            this.dropInput(event);
        }, false);

        this.$uploadInput = $('#' + this.settings.uploadInputId)
        this.$uploadInput.on('change', () => {
            this.getFile();
        });
        this.$uploadLabel.find('.remove-media').saClick((event) => {
            event.preventDefault();
            this.deleteImage();
        });

        this.$uploadButton = $('.upload-image-modal .positive');

        this.$resolutionWarning = this.get('.resolution-warning');
        this.$resolutionError = this.get('.resolution-error');
        this.get('.min-resolution').text(this.minResolutionString);
        this.get('.ideal-resolution').text(this.idealResolutionString);
        this.$resolution = this.get('.output-resolution');

        this.processingUrl = this.$uploadLabel.data().processingUrl;
        this.deleteUrl = this.$uploadLabel.data().deleteUrl;
        this.defaultImageUrl = this.$uploadLabel.data().defaultImageUrl;

        this.setupOverlays()
    }
    get(identifier){
        return $('#' + this.settings.id + ' ' + identifier);
    }
    setAspectRatio(ratio){
        this.get('')[0].style.setProperty('--aspectRatio', ratio);
    }
    get cropper(){
        return this.$image.data('cropper');
    }
    get scale(){
        const data = this.imageData
        return {
            x: data.scaleX || 1,
            y: data.scaleY || 1,
        }
    }
    get imageData(){
        return this.cropper.getImageData();
    }
    dragOverInput(event){
        event.stopPropagation();
        event.preventDefault();
        event.dataTransfer.dropEffect = 'copy';
    }
    dropInput(event){
        event.stopPropagation();
        event.preventDefault();
        this.getFile(event.dataTransfer.files[0]);
    }
    getFile(file){
        this.reset();
        file = file || this.$uploadInput[0].files[0];
        this.$uploadInput.val('');
        var reader = new FileReader();
        reader.onload = (event) => {
            sa.alertModal({
                modal: '.upload-image-modal',
                confirmation_close: false,
                neutral: () => {
                    this.closeModal();
                },
                positive: () => {
                    this.commit()
                    this.uploadImage();
                },
            });
            this.updatePreviewSize();
            this.newImage(event.target.result);
        }
        reader.readAsDataURL(file);
        sa.dashboard.setupCookies();
        var filename = file.name
        if(!this.uploadInfo || this.uploadInfo.filename !== filename){
            var url = this.$uploadLabel.data().uploadUrl
            $.ajax({
                url: `${url}?filename=${encodeURI(filename)}`,
                type: 'GET',
                success: (data) => {
                    this.uploadInfo = data;
                    this.uploadInfo.filename = filename
                },
            });
        }
    }
    get safeResolution(){
        if(!this.outputWidth || !this.outputHeight) return true
        return this.outputWidth >= this.minWidth && this.outputHeight >= this.minHeight;
    }
    get idealResolution(){
        if(!this.outputWidth || !this.outputHeight) return true
        return this.outputWidth >= this.idealWidth && this.outputHeight >= this.idealHeight;
    }
    get outputResolutionString(){
        return this.formatResolution(this.outputWidth, this.outputHeight);
    }
    get minResolutionString(){
        return this.formatResolution(this.minWidth, this.minHeight);
    }
    get idealResolutionString(){
        return this.formatResolution(this.idealWidth, this.idealHeight);
    }
    formatResolution(width, height){
        if(!width || !height) return ''
        return parseInt(width) + ' x ' + parseInt(height) + 'px';
    }
    onCrop(event){
        this.outputWidth = event.detail.width;
        this.outputHeight = event.detail.height;
        this.updateResolution();
    }
    onCropStart(event){}
    onCropEnd(event){}
    onReady(){
        this.checkInitialResolution()
    }
    checkInitialResolution(){
        var image = this.cropper.getImageData();
        var unsafe = image.naturalWidth < this.minWidth || image.naturalHeight < this.minHeight;
        this.get('').toggleClass('low-resolution', unsafe);
    }
    updateResolution(){
        var output = this.outputResolutionString;
        this.$resolution.text(output)
        this.$resolutionWarning.toggleClass('hidden', this.idealResolution || !this.safeResolution);
        this.$resolutionError.toggleClass('hidden', this.safeResolution);
        this.$uploadButton.toggleDisability(this.safeResolution)
    }
    deleteImage(){
        sa.alertModal({
            modal: '.remove-image-modal',
            confirmation_close: false,
            negative: () => {
                $('.remove-image-modal .negative').addClass('processing').disable()
                $.ajax({
                    url: this.deleteUrl,
                    type: 'GET',
                    success: () => {
                        $('.remove-image-modal').trigger('close');
                        this.removeDisplayImage();
                    },
                    error: (err) => {
                        console.error(err);
                    },
                    complete: () => {
                        $('.remove-image-modal .negative').removeClass('processing').enable();
                    },
                });
            },
        });
    }
    uploadImage(){
        this.$uploadButton.addClass('processing').disable();
        if(!this.uploadInfo){
            setTimeout(() => {
                this.uploadImage();
            }, 250);
            return;
        }
        this.cropper.getCroppedCanvas().toBlob((blob) => {
            delete $.ajaxSettings.headers["X-CSRFToken"];
            $.ajax({
                url: this.uploadInfo.url,
                data: blob,
                type: 'POST',
                processData: false,
                contentType: false,
                dataType: 'text',
                success: () => {
                    this.setDisplayImage(blob);
                    this.closeModal();
                },
                error: (jqXHR, textStatus, errorThrown) => {
                    console.error(jqXHR)
                    console.error(textStatus)
                    console.error(errorThrown)
                },
                complete: () =>{
                    this.$uploadButton.removeClass('processing').enable();
                },
            })
        }, 'image/jpeg');
    }
    newImage(imageUrl){
        this.cropper.replace(imageUrl);
    }
    setupOverlays(){
        var overlayButtons = this.get('.overlay-button')
        var overlays = this.get('.preview-overlays span')
        overlayButtons.saClick((event, element) => {
            element = $(element)
            var index = element.data('overlay')
            overlayButtons.add(overlays).removeClass('active')
            this.get(`.overlay-${index}`).add(element).addClass('active')
        })
    }
    removeDisplayImage(){
        clearTimeout(this.pollingUploadProcessing)
        this.$uploadLabel.removeClass('active processing');
        if(!this.defaultImageUrl){
            this.$uploadLabel.find('.preview').removeClass('has-image');
        }
        this.$uploadLabel.find('img').prop('src', this.defaultImageUrl || '')

    }
    setDisplayImage(blob){
        this.$uploadLabel.addClass('active processing').find('.preview').addClass('has-image');
        this.$uploadLabel.find('img').prop('src', URL.createObjectURL(blob));
        this.pollUploadProcessing()
    }
    pollUploadProcessing(){
        if(!this.processingUrl) return;
        clearTimeout(this.pollingUploadProcessing)
        this.pollingUploadProcessing = setTimeout(() => {
            $.ajax({
                url: this.processingUrl,
                type: 'GET',
                success: (data) => {
                    if(data && data !== this.defaultImageUrl){
                        this.$uploadLabel.removeClass('processing')
                    } else {
                        this.pollUploadProcessing();
                    }
                },
            });
        }, this.pollSpeed * 1000)
    }
    closeModal(){
        $('.upload-image-modal').trigger('close');
    }
    updatePreviewSize(){
        var width = this.$preview.parent().width();
        this.$preview.add(this.get('.preview-overlay')).css({
            'width': width,
            'height': width * this.aspectRatio
        });
    }
    commit(){
        this.cropper.disable();
    }
    reset(){
        this.get('').removeClass('low-resolution');
        this.cropper.reset();
        this.cropper.enable();
    }
    rotateCW(){
        this.cropper.rotate(90);
    }
    rotateCCW(){
        this.cropper.rotate(-90);
    }
    flipX(){
        this.cropper.scale(this.scale.x * -1, this.scale.y);
    }
    flipY(){
        this.cropper.scale(this.scale.x, this.scale.y * -1);
    }
}
