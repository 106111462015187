
(function() {
	this.saSite = function() {
		var _this = this;
		this.init = function () {
			$(window).resize(_this.page.resized);

			$('nav, .user-panel').addClass('loaded');
			sa.storage = function(parameter, value){
	            if(typeof(Storage) !== "undefined"){
	                if(value !== undefined){
	                    sessionStorage[parameter] = value;
	                }
	                return sessionStorage[parameter];
	            }
	        };

			_this.tooltips.setup();
			_this.page.resized();
			_this.feedback.setup();
			_this.header.setup();
			_this.truncate.setup();
			_this.truncate.setupVerticalSermonList();
			_this.messages.setup();
			_this.modals.setup();
			_this.tabNavs.setup();
			_this.backToTop.setup();

			/* INIT OUTDATED BROWSER PLUGIN */
			outdatedBrowser({
				bgColor: '#f25648',
				color: '#ffffff',
				lowerThan: 'borderImage',
				// consider using this to weed out IE11
				// lowerThan: 'transform-style: preserve-3d',
				languagePath: ''
			});
            setTimeout(function(){
                $(document).trigger('sa-site');
            }, 0);
		};
		this.backToTop = {
			setup: function(){
				$('.back-to-top').saClick(function(){
					sa.utils.scroll.to($('body'));
				});
				if(!$('.fixed-body-header').length) return;
				function updateFixedHeader(){
					var show = $('html').scrollTop() >= 100;
					$('.fixed-body-header').toggleClass('show', show);
				}
				$(window).on('scroll', updateFixedHeader);
				updateFixedHeader();
			},
		};
		this.tooltips = {
			updatePosition: function(tooltipContainer, tooltip, parent){
				var rightAlign = tooltipContainer.hasClass('right-offset');

				var parentWidth = parent.getRealDimensions().innerWidth;
				var parentOffsets = parent.offset();
				var parentPos = {
					left: parentOffsets.left,
					right: parentOffsets.left + parentWidth,
				};

				tooltip.removeAttr('style');
				tooltip.css('max-width', parentWidth);

				var tooltipOffsets = tooltip.offset();
				var shadowSize = tooltip.css('box-shadow') ? 25 : 0;
				var tooltipPos = {
					left: tooltipOffsets.left + shadowSize,
					right: tooltipOffsets.left + tooltip.getRealDimensions().outerWidth + shadowSize,
				};

				// check if we're overflowing parent
				var overflowingParent = tooltipPos.left < parentPos.left || tooltipPos.right > parentPos.right;
				if(overflowingParent){
					var overlap = tooltipPos.right - parentPos.right;
					overlap = overlap > 0 ? -overlap : overlap;
					tooltip.css(rightAlign ? 'right' : 'left', overlap);
				}

			},
			setup: function(){
				$('.tooltip').not('.init').each(function(){
					var tooltipContainer = $(this);
					var tooltipParent = $(this).data().parent;
					var parent = tooltipParent ? $(tooltipParent) : $(this).parent();
					var tooltip = tooltipContainer.find('.tooltip-target');
					$(window).on('resize', function(){
						_this.tooltips.updatePosition(tooltipContainer, tooltip, parent);
					});
					_this.tooltips.updatePosition(tooltipContainer, tooltip, parent);
					tooltipContainer.addClass('init');
				});
			},
		};
		this.tabNavs = {
			setup: function(){
				$('[data-tab-controller]').each(function(){
					var tabs = $(this).find('[data-tab]');
					var parent = $(this);
					var contents = '';
					tabs.each(function(index, element){
						contents += '[data-tab-contents="' + $(element).data().tab + '"]';
						if(index != tabs.length - 1){
							contents += ', ';
						}
					});
					contents = $(contents);
					tabs.saClick(function(){
						tabs.removeClass('active');
						$(this).addClass('active');
						contents.addClass('hidden');
						var containers = $('[data-tab-contents="' + $(this).data().tab + '"]');
						containers.removeClass('hidden');
						parent.trigger('navigated');
					});
				});
				$('[data-tab].active').trigger('click');
			},
		};
        this.messages = {
            setup: function(){
                $('.message .dismiss').saClick( function(){
                    $(this).parent().remove();
                });
            },
        };
		this.modals = {
			fillCheck: function(el){
				var container = el.find('.modal-contents-container');
                if($(window).height() < container[0].scrollHeight){
					if(!el.hasClass('fill')){
						el.addClass('fill');
					}
                } else{
					el.removeClass('fill');
				}
			},
			setup: function(){
				$('.modal-container.alert, .modal-container.submit').each(function(){
					var element = $(this);
					$(window).resize(function(){
						_this.modals.fillCheck(element);
					});
					_this.modals.fillCheck(element);
				});
				$('.modal-sub-container').each(function(){
					var parent = $(this).parent()[0];
					var subContainer = $(this);
					$(window).on('resize', function(){
						subContainer.removeAttr('style');
						var height = parent.scrollHeight;
						subContainer.css('height', height);
					});
				});
			},
		}
		this.truncate = {
            setupVerticalSermonList: function(){
                $('.vertical-sermon-list').each(function(){
					var trunc = $(this);
					var button = $('#' + trunc.data('more'));
					var sermons = $(this).find('.sermons');
					var maxHeight = parseFloat(sermons.css('max-height'));
					var keepButton = maxHeight < sermons[0].scrollHeight;
					if (!keepButton){
						button.remove();
					} else {
						button.saClick(function(){
							trunc.toggleClass('opened');
							$('body, html').animate ({
								scrollTop: $('body, html').scrollTop() + sermons[0].scrollHeight
							}, 1500);
						});
					}
				});
            },
			setup: function(){
				$('.truncate').each(function(){
					var trunc = $(this);
					var button = $('#' + trunc.data('more'));
					var maxHeight = parseFloat(trunc.css('max-height'));
					button.saClick(function(){
						trunc.toggleClass('opened');
                        $(this).toggleClass('opened');
					});
					$(window).on('resize', function(){
						_this.truncate.check(trunc, button, maxHeight);
					});
					_this.truncate.check(trunc, button, maxHeight);
				});
			},
			check: function(trunc, button, maxHeight){
				var truncate = maxHeight < trunc.find('span')[0].scrollHeight;
				var hasClass = trunc.hasClass('oversized');
				if(!hasClass && truncate){
					trunc.addClass('oversized');
					button.addClass('oversized');
				} else if (!truncate && hasClass){
					trunc.removeClass('oversized');
					button.removeClass('oversized');
				}
			},
		};
		this.regexEscape = function(string){
			return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
		};
		this.regexSanitize = function(string){
			return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '');
		};
        this.header = {
			previousTop: 0,
			shader: {
				show: function(){
					_this.header.previousTop = window.pageYOffset;
					$('body').addClass('wrapper-open');
					$('body').css('top', -_this.header.previousTop + 'px');
				},
				hide: function(){
					$('body').removeClass('wrapper-open');
					$('body').addClass('wrapper-transitioning');
					_this.header.panel.hide('user-panel');
					_this.header.panel.hide('nav');
					$('body').one(sa.animationPrefixes, function() {
						$('body').removeClass('wrapper-transitioning');
						window.scrollTo(0, _this.header.previousTop);
						$('body').css('top', 0);
					});
				}
			},
			panel: {
				show: function(panelName){
					$('body').addClass( panelName + '-open' );
					$('header .' + panelName + '-toggle .menu-icon').addClass('open');
				},
				hide: function(panelName){
					$('body').removeClass(panelName + '-open to-' + panelName);
					$('header .' + panelName + '-toggle .menu-icon').removeClass('open');
				}
			},
            toggleSearch: function(){
				if($('header').hasClass('search-open')){
					$('header').addClass('search-closed');
					$('header').removeClass('search-open');
				} else {
					$('header').removeClass('search-closed');
					$('header').addClass('search-open');
				}
            },
            setup: function(){
                $('header .search-back-button').saClick(function(){
                    _this.header.toggleSearch();
                });
                $('header .search-button').saClick(function(){
                    if($('header').hasClass('search-closed')){
                        _this.header.toggleSearch();
                    }
                });
				$('.user-panel-toggle').saClick(function(){
					if($('body').hasClass('user-panel-open')){
						_this.header.panel.hide('user-panel');
						_this.header.shader.hide();
					} else {
						if(!$('body').hasClass('wrapper-open')){
							_this.header.shader.show();
						} else {
							_this.header.panel.hide('nav');
							$('body').addClass('panels-switching');
							$('body').one(sa.animationPrefixes, function() {
								$('body').removeClass('panels-switching');
							});
						}
						_this.header.panel.show('user-panel');
					}
				});
				$('.nav-toggle').saClick(function(){
					if($('body').hasClass('nav-open')){
						_this.header.panel.hide('nav');
						_this.header.shader.hide();
					} else {
						if(!$('body').hasClass('wrapper-open')){
							_this.header.shader.show();
						} else {
							_this.header.panel.hide('user-panel');
							$('body').addClass('panels-switching');
							$('body').one(sa.animationPrefixes, function() {
								$('body').removeClass('panels-switching');
							});
						}
						_this.header.panel.show('nav');
					}
				});
				$('.panel-shader').on('click', function(){
					_this.header.shader.hide();
				});
                sa.search.subscribe($('.search-input.header-search'), function(){
					// TODO: this will one day need to be a search results or advanced search page
                    window.location.href = window.location.origin + '/?q=' + $('.search-input.header-search').val();
                });
            }
        };
		this.feedback = {
			minCommentCharacters: 10,
			submit: function(details, success, error){
				var json = $.extend(true, details, {
					"url": window.location.href,
                    "errors": sa.errors,
				});
				$.ajax({
					type: "POST",
					url: '/feedback/',
					data: JSON.stringify(json),
					success: success,
					error: error,
					contentType: "application/json"
				});
			},
			setup: function(){
				var feedback = $('.feedback-modal');
				var closeModal = function(){
					feedback.removeClass('active');
					sa.aria.modalize.out('.feedback-modal', '.feedback-modal .modal-close-button');
				};
				$('#site-feedback-target, .feedback-modal .modal-close-button, .feedback-modal .modal-background').saClick(function(){
					if(!feedback.hasClass('init')){
						feedback.addClass('init');
					}
					if(feedback.hasClass('active')){
						closeModal();
					} else {
						feedback.addClass('active');
						sa.aria.modalize.in('.feedback-modal', '.feedback-modal .modal-close-button', function(){
                            closeModal();
                        });
					}
				});
				$('#site-feedback').on('submit',function (e){
					e.preventDefault();
					var comment = $('#site-feedback-text').val();
                    var message = $('#site-feedback-message');
					if(comment.length >= _this.feedback.minCommentCharacters){
						var name = $('#site-feedback-name').val();
						var email = $('#site-feedback-email').val();
						var submitButton = $('#site-feedback-submit');
						submitButton.prop('disabled', true);
						_this.feedback.submit({
							name: name,
							email: email,
							body: comment
						}, function(event){
							//success
							submitButton.text(gettext('Thanks!'));
							message.addClass('success').text(gettext('Your feedback has been sent.'));
							$('#site-feedback input, #site-feedback textarea').prop('disabled', true);
							setTimeout(function(){
								closeModal();
							}, 1000);
						}, function(event){
							//error
							submitButton.prop('disabled', false);
							message.addClass('error').text(gettext('Something went wrong. Please try again.'));
						});
					} else {
						var translatedString = ngettext('Please include a comment that is at least %s character.', 'Please include a comment that is at least %s characters.', _this.feedback.minCommentCharacters);
						message.addClass('error').text(interpolate(translatedString,[_this.feedback.minCommentCharacters]));
					}
				});
			}
		};
		this.page = { // Page variables
			breakpoints: [480,680], // window breakpoints used to make css changes on window size changes
			sizing: {
				delay: 100, // ms before resizing function fires
				timeout: null,
			},
			resized: function (){
				clearTimeout(_this.page.sizing.timeout);
				_this.page.sizing.timeout = setTimeout(function(){

				}, _this.page.sizing.delay);
			}
		};
        this.pseudoInputs = {
            setup: function(params){
                params.$target  = $(params.target_string);
                params.$input   = $(params.target_string + ' .active-' + params.type);
                params.$display = $(params.target_string + ' .selected-' + params.type);
                params.$search  = $(params.target_string + ' .' + params.type + '-search-input');

                var openFunction = function(event){
                    if(params.$target.hasClass('active')){
                        _this.pseudoInputs.closeDropdown(params);
                    } else {
                        sa.aria.modalize.in(params.target_string, null, function(){
                            _this.pseudoInputs.closeDropdown(params);
                        });
                        params.$target.removeClass('error')
                        .addClass('active')
                        .offClick(function(){
                            _this.pseudoInputs.closeDropdown(params);
                        });
                        setTimeout(function(){
                            params.$search.focus();
                        }, 0);
                    }
                };
                params.$display.on('click', openFunction);
                params.$target.keysEvent([sa.keycodes.enter], 'keydown.target', function(event){
                    if(!params.$target.hasClass('active')){
                        openFunction();
                    } else if ($(':focus').is('li')) {
                        $(':focus').click();
                    } else if ($(':focus').is(params.$search)){
                        var searchL = params.$target.find('.search-results-container li');
                        if(searchL.length > 0){
                            searchL[0].focus();
                        }
                    }
                });
                params.$clear = params.$target.find('.clear-selection')
                params.$clear.saClick(function(event){
                    event.stopPropagation();
                    params.$input.val('');
                    params.$target.removeClass('selected').find('li.active').removeClass('active');
                    params.$display.text(params.$target.data('placeholder'));
                    params.click_function(undefined, null);
                });
                _this.pseudoInputs.setupSearch(params);
                _this.pseudoInputs.bindListEvents(params);
                return params;
            },
            setupSearch: function(params){
                var lis = $(params.target_string + ' ' + 'li:not(.exclude-in-search)');
                params.$search.on('keydown', function(event){
                    var element = $(this);
                    var key = event.keyCode;
                    var keys = sa.keycodes;
                    if(key != keys.enter && key != keys.tab && key != keys.arrowUp && key != keys.arrowDown){
                        setTimeout( function(){
                            var value = element.val().toLowerCase();
                            lis.each(function(){
                                var liValue = $(this).attr('value').toLowerCase();
                                if(liValue.indexOf(value) != -1){
                                    $(this).removeClass('hide');
                                } else if (!$(this).hasClass('hide')){
                                    $(this).addClass('hide');
                                }
                            });
                            if(params.search_function){
                                params.search_function();
                            }
                        }, 1);
                    }
                });
            },
            closeDropdown: function(params){
                sa.aria.modalize.out(params.target_string, null, true);
                params.$target.removeClass('active').attr('tabindex', 0);
            },
            reBindLis: function(params){
                var lis = $(params.target_string + ' li:not(.add-new)');
                var activate = function(li){
                    lis.removeClass('active');
                    li.addClass('active');
                    if(!params.$target.hasClass('selected')){
                        params.$target.addClass('selected');
                    }
					var val = li.attr('value');
                    params.$input.val(val);
                    params.$display.text(val);
                    params.click_function(li, val);
                };
                lis.saClick(function(){
                    activate($(this));
                    _this.pseudoInputs.closeDropdown(params);
                });
                lis.on('focus', function(){
                    activate($(this));
                });
                var toSearch = $(params.target_string + ' li').add(params.$search);
                toSearch.keysEvent([sa.keycodes.arrowUp, sa.keycodes.arrowDown], 'keydown', function(event){
                    var visible = toSearch.not('.hide').not('.dummy');
                    var index = visible.index($(this));
                    var first = index == 0;
                    var last = index == visible.length;
                    if(event.keyCode == sa.keycodes.arrowUp){
                        if (!first){
                            index -= 1;
                        }
                    } else {
                        if(!last){
                            index += 1;
                        }
                    }
                    $(visible.eq(index)).focus();
                });
            },
            bindListEvents: function(params){
                params.$target.find('.add-new').saClick(function(event){
					var input = $(this).find('input[type="text"]');
                    var val = input.val();
                    if(params.$target.hasClass('requires-search') && !params.$target.hasClass('has-searched')){
                        event.preventDefault();
                        sa.toasts.toast(gettext('Please search for your speaker first to verify they are not already in our system.'), sa.toasts.types.warning);
                        params.$search.focus();
                    } else if (!params.$target.hasClass('adding')) {
                        event.preventDefault();
                        params.$target.addClass('adding');
                        input.val(params.$search.val()).focus();
                        sa.aria.outline.update();
                    }
                }, '', true);
                var button = params.$target.find('input[type="button"]');
                var submitFunction = function(event){
                    event.preventDefault();
                    event.stopPropagation();
                    var newValInput = params.$target.find('.add-new input[type="text"]');
                    var newVal = newValInput.val();
                    var min = newValInput.data('min-length');
                    if(newVal.length < min){
                        var text = gettext('Please enter text that is at least %s characters in length.');
                        var interpolated = interpolate(text,[min]);
                        sa.toasts.toast(interpolated, sa.toasts.types.warning);
                    } else if(newVal != '' && newVal != undefined){
                        params.$input.val(newVal);
                        params.$display.text(newVal);
                        params.$target.addClass('selected');
                        params.click_function(newValInput, newValInput.val());
                        _this.pseudoInputs.closeDropdown(params);
                    } else {
                        sa.toasts.toast(gettext('Please enter valid text.'), sa.toasts.types.warning);
                    }
                };
                button.saClick(submitFunction);
                params.$target.find('.add-new input[type="text"]').keysEvent([sa.keycodes.enter], 'keydown', submitFunction);

                _this.pseudoInputs.reBindLis(params);
            },
        };
		this.mixColors = function (color1, color2, percent) {
			// https://stackoverflow.com/questions/28016890/how-to-make-a-color-similar-to-another-color-by-percentage-in-javascript
			function mix(start, end, percent) {
				return start + ((percent) * (end - start));
			}
			function generateHex(r, g, b) {
				r = r.toString(16);
				g = g.toString(16);
				b = b.toString(16);

				while (r.length < 2) { r = "0" + r; }
				while (g.length < 2) { g = "0" + g; }
				while (b.length < 2) { b = "0" + b; }

				return "#" + r + g + b;
			}

			var red1 = parseInt(color1[1] + color1[2], 16);
			var green1 = parseInt(color1[3] + color1[4], 16);
			var blue1 = parseInt(color1[5] + color1[6], 16);

			var red2 = parseInt(color2[1] + color2[2], 16);
			var green2 = parseInt(color2[3] + color2[4], 16);
			var blue2 = parseInt(color2[5] + color2[6], 16);

			var red = Math.round(mix(red1, red2, percent));
			var green = Math.round(mix(green1, green2, percent));
			var blue = Math.round(mix(blue1, blue2, percent));

			return generateHex(red, green, blue);
		}
        _this.init();
	};
}());

$(document).on('sa', function(){
	sa.site = new saSite();
});
