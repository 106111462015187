(function() {
    this.saSermonPage = function() {
        var _this = this;
        this.embedCode = '';
        this.player = {};
        this.settings = {
            maxDescHeight: 120,
        };
        this.playing = function(){
            return _this.isPlaying();
        };
        this.activePlayer = function(){
            if($('.player').hasClass('audio')){
                return _this.player.audio;
            } else {
                return _this.player.video;
            }
        };
        this.isPlaying = function(){
            return _this.activePlayer().isPlaying();
        };
        this.pause = function(){
            sa.audio.pauseAll();
            sa.video.pauseAll();
        };
        this.play = function(time){
            _this.activePlayer().play(time);
        };
        this.init = function (id) {
            _this.player.audio = sa.audio.getPlayerById(id);
            _this.player.video = sa.video.getVideoById(id);
            $('.media-type button').saClick(function(event){
                if(!$(this).hasClass('active')){
                    $('.media-type button').removeClass('active');
                    $(this).addClass('active');
                    _this.time = _this.activePlayer().currentTime();
                    var continuePlaying = false;
                    if(_this.isPlaying()){
                        continuePlaying = true;
                    }
                    _this.pause();
                    $('.player').toggleClass("audio video");
                    _this.player.audio.waveform.resize();
                    if(continuePlaying){
                        _this.play(_this.time);
                    }
                }
            });
            $('.open-podcast').saClick(function(event){
                $('.open-podcast, .podcast-dropdown').toggleClass('closed open');
            });
        };
    };
}());
