// This file/class uses Babel + "ES6"

class saSpeakerSelector{
    constructor(settings){
        // settings parameters:
        // container_id
        // on_selection - function that fires when a speaker is selected
        // require_recent_and_frequent - optional
        this.settings = settings;
        this.searched = false;
        this.currentSpeakers = [];
        this.minimumCharacters = 3;
        this.maxSearchResults = 15;
        this.newSpeakersTimeout = null;
        this.speakerSearchDelay = 200; //ms
        this.containerId = '#' + this.settings.container_id;
        this.container = $(this.containerId);
        this.searchUrl = this.container.data('searchUrl');
        this.setup();
	}
    find(elements){
        return this.container.find(elements);
    }
    clear(){
        this.params.$clear.trigger('click')
    }
    setup(){
        this.params = sa.site.pseudoInputs.setup({
            target_string: this.containerId,
            type: 'speaker',
            click_function:(li, value) => {
                var imgHTML = li ? li.find('.speaker-img').html() : '';
                this.settings.on_selection(value, imgHTML);
            },
            search_function: () => {
                this.getNewSpeakers();
                this.find('.recent-speaker-title', '.frequent-speaker-title').addClass('hidden');
                this.find('[data-title-div]:not(.hide)').each((index, element) => {
                    var titleDiv = $(element).data('title-div');
                    this.find(titleDiv).removeClass('hidden');
                });
            },
        });
        var verbiage = gettext('Top %s Results');
        this.find('.search-results-title').text(interpolate(verbiage,[this.maxSearchResults]));
        var dummy = this.find('.dummy');
        this.clone = dummy.clone().removeClass('dummy');
        dummy.remove();
    }
    getNewSpeakers(afterSearchFunc){
        var searchResults = this.find('.search-results-container');
        var query = this.params.$search.val();
        clearTimeout(this.newSpeakersTimeout);
        if(this.newSpeakersPost){
            this.newSpeakersPost.abort();
        }
        if(query.length < this.minimumCharacters){
            this.params.$target.removeClass('searching loading');
            searchResults.html('');
            return;
        } else {
            this.params.$target.addClass('loading');
            if(!this.params.$target.hasClass('searching')){
                this.params.$target.addClass('searching');
            }
        }
        this.newSpeakersTimeout = setTimeout(() => {
            sa.setupCSRF();
            this.newSpeakersPost = $.post(this.searchUrl , { query: query, pageSize: this.maxSearchResults, }, (data) => {
                this.params.$target.removeClass('loading');
                if(!this.params.$target.hasClass('has-searched')){
                    this.params.$target.addClass('has-searched');
                }
                searchResults.html('');
                // no results
                if(!data) return;
                var searchedSpeakers = data;
                if(this.maxSearchResults > searchedSpeakers.length){
                    var verbiage = ngettext('%s Result', '%s Results', searchedSpeakers.length);
                    this.find('.search-results-title').text(interpolate(verbiage,[searchedSpeakers.length]));
                } else {
                    var verbiage = gettext('Top %s Results');
                    this.find('.search-results-title').text(interpolate(verbiage,[this.maxSearchResults]));
                }

                for (var i = 0; i < searchedSpeakers.length; i++){
                        var speaker = searchedSpeakers[i];
                        var newLi = this.clone.clone();
                        var translatedString = ngettext('%s Sermon', '%s Sermons', speaker.sermonCount);
                        newLi.find('.name').text(speaker.displayName);
                        newLi.attr({
                            'value': speaker.displayName,
                            'tabindex': 0,
                        });
                        newLi.find('img').attr("src", speaker.roundedThumbnailImageURL);
                        newLi.find('.count').text(interpolate(translatedString,[speaker.sermonCount]));
                        searchResults.append(newLi);
                }
                sa.site.pseudoInputs.reBindLis(this.params);
                sa.aria.outline.update();
            }).fail((data) => {
                if(data.statusText != 'abort'){
                    this.params.$target.removeClass('loading');
                    console.error(data);
                }
            });
        }, this.speakerSearchDelay);
    }
}
