(function() {
    this.saBible = function() {
        var _this = this;
		this.oldTestamentBookCutoff = 39; // last book in the OT
		this.similarBookCuttoffPercentage = .5; //  how close does a book title have to be to an exact match as a percentage with 1 being an exact match (case insensitive)
        this.getChapterCount = function(book){
			if(!book){
				console.error('"' + book + '" is not a valid book.');
				return null;
			}
            return _this.chapters[book].length;
        };
        this.getVerseCount = function(book, chapter){
			if(!book || !chapter){
				if(!book){
					console.error('"' + book + '" is not a valid book.');
				}
				if(!chapter){
					console.error('"' + chapter + '" is not a valid chapter.');
				}
				return null;
			}
            return _this.chapters[book][chapter - 1];
        };
		this.getBookIndex = function(book){
			book = book.toLowerCase();
			for(var i = 0; i < _this.books.length; i++){
				if(_this.books[i].toLowerCase() == book || _this.booksTranslated[i].toLowerCase() == book) return i;
			}
			return undefined;
		};
		this.filteredBookArray = function(book){
			if(!book) return [];
			var en = _this.books[0] == _this.booksTranslated[0];
			var newArray = _this.books;
			var uniqueArray = [];
			if(!en){
				newArray = newArray.concat(_this.booksTranslated);
				$.each(newArray, function(i, el){
				    if($.inArray(el, uniqueArray) === -1) uniqueArray.push(el);
				});
			} else {
				uniqueArray = newArray;
			}
			newArray = [];
			var filterFunction;
			book = book.replace(' ', '');
			if(/[0-9]/g.test(book[0])){
				// first character is a number
				filterFunction = function(b){
					return b[0].toLowerCase() === book[0] || b[0].toLowerCase() === book[1];
				};
			} else {
				// first character is not a number
				filterFunction = function(b){
					if(/[0-9]/g.test(b[0])){
						return b.replace(' ', '').toLowerCase()[1] === book[0];
					} else {
						return b[0].toLowerCase() === book[0];
					}
				};
			}
			uniqueArray = uniqueArray.filter(filterFunction);
			for(var i = 0; i < uniqueArray.length; i++){
				var item = uniqueArray[i];
				newArray.push({book: item, matchAgainst: item, abbreviation: false});
				var abr = _this.abbreviations[item];
				if(abr != undefined){
					for(var x = 0; x < abr.length; x++){
						newArray.push({book: item, matchAgainst: abr[x], abbreviation: true});
					}
				}
			}
			return newArray;
		};
		this.matchBook = function(book){
			book = book.toLowerCase().trim();
			var newArray = _this.filteredBookArray(book);
			var bestMatch;
			newArray.forEach(function(a){
				var percentage = _this.similarity(book, a.matchAgainst.toLowerCase());
				if(percentage >= _this.similarBookCuttoffPercentage){
					if(!bestMatch || percentage > bestMatch.match){
						bestMatch = {book: a.book, match: percentage, abbreviation: a.abbreviation, index: _this.getBookIndex(a.book) };
					}
				}
			});
			return { best: bestMatch };
		};
		this.isOldTestament = function(book){
			var ot = true;
			if(typeof(book) == String){
				for(var i = 0; i < _this.books.length; i++){
					if(_this.books[i].indexOf(book) != -1){
						if(i < _this.oldTestamentBookCutoff){
							return true;
						} else {
							return false;
						}
					}
				}
			} else {
				if(book < _this.oldTestamentBookCutoff){
					return true;
				} else {
					return false;
				}
			}
			console.error('"' + book + '" is not a valid book');
			return false;
		};
		this.books = [
		    'Genesis',         'Exodus',          'Leviticus',     'Numbers',
		    'Deuteronomy',     'Joshua',          'Judges',        'Ruth',
		    '1 Samuel',        '2 Samuel',        '1 Kings',       '2 Kings',
		    '1 Chronicles',    '2 Chronicles',    'Ezra',          'Nehemiah',
		    'Esther',          'Job',             'Psalm',         'Proverbs',
		    'Ecclesiastes',    'Song of Solomon', 'Isaiah',        'Jeremiah',
		    'Lamentations',    'Ezekiel',         'Daniel',        'Hosea',
		    'Joel',            'Amos',            'Obadiah',       'Jonah',
		    'Micah',           'Nahum',           'Habakkuk',      'Zephaniah',
		    'Haggai',          'Zechariah',       'Malachi',       'Matthew',
		    'Mark',            'Luke',            'John',          'Acts',
		    'Romans',          '1 Corinthians',   '2 Corinthians', 'Galatians',
		    'Ephesians',       'Philippians',     'Colossians',    '1 Thessalonians',
		    '2 Thessalonians', '1 Timothy',       '2 Timothy',     'Titus',
		    'Philemon',        'Hebrews',         'James',         '1 Peter',
		    '2 Peter',         '1 John',          '2 John',        '3 John',
		    'Jude',            'Revelation'
		];
		this.booksTranslated = [
			gettext('Genesis'),      	gettext('Exodus'),          gettext('Leviticus'),     gettext('Numbers'),
			gettext('Deuteronomy'),     gettext('Joshua'),          gettext('Judges'),        gettext('Ruth'),
			gettext('1 Samuel'),        gettext('2 Samuel'),        gettext('1 Kings'),       gettext('2 Kings'),
			gettext('1 Chronicles'),    gettext('2 Chronicles'),    gettext('Ezra'),          gettext('Nehemiah'),
			gettext('Esther'),          gettext('Job'),             gettext('Psalm'),         gettext('Proverbs'),
			gettext('Ecclesiastes'),    gettext('Song of Solomon'), gettext('Isaiah'),        gettext('Jeremiah'),
			gettext('Lamentations'),    gettext('Ezekiel'),         gettext('Daniel'),        gettext('Hosea'),
			gettext('Joel'),            gettext('Amos'),            gettext('Obadiah'),       gettext('Jonah'),
			gettext('Micah'),           gettext('Nahum'),           gettext('Habakkuk'),      gettext('Zephaniah'),
			gettext('Haggai'),          gettext('Zechariah'),       gettext('Malachi'),       gettext('Matthew'),
			gettext('Mark'),            gettext('Luke'),            gettext('John'),          gettext('Acts'),
			gettext('Romans'),          gettext('1 Corinthians'),   gettext('2 Corinthians'), gettext('Galatians'),
			gettext('Ephesians'),       gettext('Philippians'),     gettext('Colossians'),    gettext('1 Thessalonians'),
			gettext('2 Thessalonians'), gettext('1 Timothy'),       gettext('2 Timothy'),     gettext('Titus'),
			gettext('Philemon'),        gettext('Hebrews'),         gettext('James'),         gettext('1 Peter'),
			gettext('2 Peter'),         gettext('1 John'),          gettext('2 John'),        gettext('3 John'),
			gettext('Jude'),            gettext('Revelation'),
		];
		this.translateBooks = function(text){
			for(var i = 0; i < _this.books.length; i++){
				if(text.indexOf(_this.books[i]) > -1){
					text = text.replace(_this.books[i], _this.booksTranslated[i]);
				}
			}
			return text;
		};
        this.chapters = {
			'Genesis': [31,25,24,26,32,22,24,22,29,32,32,20,18,24,21,16,27,33,38,18,34,24,20,67,34,35,46,22,35,43,55,32,20,31,29,43,36,30,23,23,57,38,34,34,28,34,31,22,33,26],
			'Exodus': [22,25,22,31,23,30,25,32,35,29,10,51,22,31,27,36,16,27,25,26,36,31,33,18,40,37,21,43,46,38,18,35,23,35,35,38,29,31,43,38],
			'Leviticus': [17,16,17,35,19,30,38,36,24,20,47,8,59,57,33,34,16,30,37,27,24,33,44,23,55,46,34],
			'Numbers': [54,34,51,49,31,27,89,26,23,36,35,16,33,45,41,50,13,32,22,29,35,41,30,25,18,65,23,31,40,16,54,42,56,29,34,13],
			'Deuteronomy': [46,37,29,49,33,25,26,20,29,22,32,32,18,29,23,22,20,22,21,20,23,30,25,22,19,19,26,68,29,20,30,52,29,12],
			'Joshua': [18,24,17,24,15,27,26,35,27,43,23,24,33,15,63,10,18,28,51,9,45,34,16,33],
			'Judges': [36,23,31,24,31,40,25,35,57,18,40,15,25,20,20,31,13,31,30,48,25],
			'Ruth': [22,23,18,22],
			'1 Samuel': [28,36,21,22,12,21,17,22,27,27,15,25,23,52,35,23,58,30,24,42,15,23,29,22,44,25,12,25,11,31,13],
			'2 Samuel': [27,32,39,12,25,23,29,18,13,19,27,31,39,33,37,23,29,33,43,26,22,51,39,25],
			'1 Kings': [53,46,28,34,18,38,51,66,28,29,43,33,34,31,34,34,24,46,21,43,29,53],
			'2 Kings': [18,25,27,44,27,33,20,29,37,36,21,21,25,29,38,20,41,37,37,21,26,20,37,20,30],
			'1 Chronicles': [54,55,24,43,26,81,40,40,44,14,47,40,14,17,29,43,27,17,19,8,30,19,32,31,31,32,34,21,30],
			'2 Chronicles': [17,18,17,22,14,42,22,18,31,19,23,16,22,15,19,14,19,34,11,37,20,12,21,27,28,23,9,27,36,27,21,33,25,33,27,23],
			'Ezra': [11,70,13,24,17,22,28,36,15,44],
			'Nehemiah': [11,20,32,23,19,19,73,18,38,39,36,47,31],
			'Esther': [22,23,15,17,14,14,10,17,32,3],
			'Job': [22,13,26,21,27,30,21,22,35,22,20,25,28,22,35,22,16,21,29,29,34,30,17,25,6,14,23,28,25,31,40,22,33,37,16,33,24,41,30,24,34,17],
			'Psalm': [6,12,8,8,12,10,17,9,20,18,7,8,6,7,5,11,15,50,14,9,13,31,6,10,22,12,14,9,11,12,24,11,22,22,28,12,40,22,13,17,13,11,5,26,17,11,9,14,20,23,19,9,6,7,23,13,11,11,17,12,8,12,11,10,13,20,7,35,36,5,24,20,28,23,10,12,20,72,13,19,16,8,18,12,13,17,7,18,52,17,16,15,5,23,11,13,12,9,9,5,8,28,22,35,45,48,43,13,31,7,10,10,9,8,18,19,2,29,176,7,8,9,4,8,5,6,5,6,8,8,3,18,3,3,21,26,9,8,24,13,10,7,12,15,21,10,20,14,9,6],
			'Proverbs': [33,22,35,27,23,35,27,36,18,32,31,28,25,35,33,33,28,24,29,30,31,29,35,34,28,28,27,28,27,33,31],
			'Ecclesiastes': [18,26,22,16,20,12,29,17,18,20,10,14],
			'Song of Solomon': [17,17,11,16,16,13,13,14],
			'Isaiah': [31,22,26,6,30,13,25,22,21,34,16,6,22,32,9,14,14,7,25,6,17,25,18,23,12,21,13,29,24,33,9,20,24,17,10,22,38,22,8,31,29,25,28,28,25,13,15,22,26,11,23,15,12,17,13,12,21,14,21,22,11,12,19,12,25,24],
			'Jeremiah': [19,37,25,31,31,30,34,22,26,25,23,17,27,22,21,21,27,23,15,18,14,30,40,10,38,24,22,17,32,24,40,44,26,22,19,32,21,28,18,16,18,22,13,30,5,28,7,47,39,46,64,34],
			'Lamentations': [22,22,66,22,22],
			'Ezekiel': [28,10,27,17,17,14,27,18,11,22,25,28,23,23,8,63,24,32,14,49,32,31,49,27,17,21,36,26,21,26,18,32,33,31,15,38,28,23,29,49,26,20,27,31,25,24,23,35],
			'Daniel': [21,49,30,37,31,28,28,27,27,21,45,13],
			'Hosea': [11,23,5,19,15,11,16,14,17,15,12,14,16,9],
			'Joel': [20,32,21],
			'Amos': [15,16,15,13,27,14,17,14,15],
			'Obadiah': [21],
			'Jonah': [17,10,10,11],
			'Micah': [16,13,12,13,15,16,20],
			'Nahum': [15,13,19],
			'Habakkuk': [17,20,19],
			'Zephaniah': [18,15,20],
			'Haggai': [15,23],
			'Zechariah': [21,13,10,14,11,15,14,23,17,12,17,14,9,21],
			'Malachi': [14,17,18,6],
			'Matthew': [25,23,17,25,48,34,29,34,38,42,30,50,58,36,39,28,27,35,30,34,46,46,39,51,46,75,66,20],
			'Mark': [45,28,35,41,43,56,37,38,50,52,33,44,37,72,47,20],
			'Luke': [80,52,38,44,39,49,50,56,62,42,54,59,35,35,32,31,37,43,48,47,38,71,56,53],
			'John': [51,25,36,54,47,71,53,59,41,42,57,50,38,31,27,33,26,40,42,31,25],
			'Acts': [26,47,26,37,42,15,60,40,43,48,30,25,52,28,41,40,34,28,41,38,40,30,35,27,27,32,44,31],
			'Romans': [32,29,31,25,21,23,25,39,33,21,36,21,14,23,33,27],
			'1 Corinthians': [31,16,23,21,13,20,40,13,27,33,34,31,13,40,58,24],
			'2 Corinthians': [24,17,18,18,21,18,16,24,15,18,33,21,14],
			'Galatians': [24,21,29,31,26,18],
			'Ephesians': [23,22,21,32,33,24],
			'Philippians': [30,30,21,23],
			'Colossians': [29,23,25,18],
			'1 Thessalonians': [10,20,13,18,28],
			'2 Thessalonians': [12,17,18],
			'1 Timothy': [20,15,16,16,25,21],
			'2 Timothy': [18,26,17,22],
			'Titus': [16,15,15],
			'Philemon': [25],
			'Hebrews': [14,18,19,16,14,20,28,13,28,39,40,29,25],
			'James': [27,26,18,17,20],
			'1 Peter': [25,25,22,19,14],
			'2 Peter': [21,22,18],
			'1 John': [10,29,24,21,21],
			'2 John': [13],
			'3 John': [15],
			'Jude': [25],
			'Revelation': [20,29,22,11,14,17,17,13,21,11,19,17,18,20,8,21,18,24,21,15,27,21]
        };
		this.abbreviations = {
			'Genesis': [ 'Gen' ],
			'Exodus': [ 'Exod', 'Ex' ],
			'Leviticus': [ 'Lev' ],
			'Numbers': [ 'Num' ],
			'Deuteronomy': [ 'Deut' ],
			'Joshua': [ 'Josh' ],
			'Judges': [ 'Judg' ],
			'1 Samuel': [ '1 Sam', '1Sam', '1st Sam', '1st Samuel', 'First Samuel' ],
			'2 Samuel': [ '2 Sam', '2Sam', '2nd Sam', '2nd Samuel', 'Second Samuel' ],
			'1 Kings': [ '1 Kgs', '1Kgs', '1st Kgs', '1st Kings', 'First Kings' ],
			'2 Kings': [ '2 Kgs', '2Kgs', '2nd Kgs', '2nd Kings', 'Second Kings' ],
			'1 Chronicles': [ '1 Chr', '1Chr', '1st Chr', '1st Chronicles', 'First Chronicles' ],
			'2 Chronicles': [ '2 Chr', '2Chr', '2nd Chr', '2nd Chronicles', 'Second Chronicles' ],
			'Nehemiah': [ 'Neh' ],
			'Esther': [ 'Esth', 'Est' ],
			'Psalm': [ 'Ps', 'Psalms' ],
			'Proverbs': [ 'Prov' ],
			'Ecclesiastes': [ 'Eccl', 'Ecc' ],
			'Song of Solomon': [ 'Song', 'Song of Songs' ],
			'Isaiah': [ 'Isa' ],
			'Jeremiah': [ 'Jer' ],
			'Lamentations': [ 'Lam' ],
			'Ezekiel': [ 'Ezek' ],
			'Daniel': [ 'Dan' ],
			'Hosea': [ 'Hos' ],
			'Obadiah': [ 'Obad', 'Oba' ],
			'Jonah': [ 'Jon', 'Jnh' ],
			'Micah': [ 'Mic' ],
			'Nahum': [ 'Nah' ],
			'Habakkuk': [ 'Hab' ],
			'Zephaniah': [ 'Zeph' ],
			'Haggai': [ 'Hag' ],
			'Zechariah': [ 'Zech' ],
			'Malachi': [ 'Mal' ],
			'Matthew': [ 'Matt' ],
			'John': [ 'Jn', 'Jhn', 'Joh' ],
			'Romans': [ 'Rom' ],
			'1 Corinthians': [ '1 Cor', '1Cor', '1st Cor', '1st Corinthians', 'First Corinthians' ],
			'2 Corinthians': [ '2 Cor', '2Cor', '2nd Cor', '2nd Corinthians', 'Second Corinthians' ],
			'Galatians': [ 'Gal' ],
			'Ephesians': [ 'Eph' ],
			'Philippians': [ 'Phil' ],
			'Colossians': [ 'Col' ],
			'1 Thessalonians': [ '1 Thess', '1Thess', '1st Thess', '1st Thessalonians', 'First Thessalonians' ],
			'2 Thessalonians': [ '2 Thess', '2Thess', '2nd Thess', '2nd Thessalonians', 'Second Thessalonians' ],
			'1 Timothy': [ '1 Tim', '1Tim', '1st Tim', '1st Timothy', 'First Timothy' ],
			'2 Timothy': [ '2 Tim', '2Tim', '2nd Tim', '2nd Timothy', 'Second Timothy' ],
			'Philemon': [ 'Phlm', 'Philem', 'Phm', 'Pm' ],
			'Hebrews': [ 'Heb' ],
			'James': [ 'Jas' ],
			'1 Peter': [ '1 Pet', '1Pet', '1st Pet', '1st Peter', 'First Peter' ],
			'2 Peter': [ '2 Pet', '2Pet', '2nd Pet', '2nd Peter', 'Second Peter' ],
			'1 John': [ '1st John', '1 Jn', '1Jn', 'First John' ],
			'2 John': [ '2nd John', '2 Jn', '2Jn', 'Second John' ],
            '3 John': [ '3rd John', '3 Jn', '3Jn', 'Third John' ],
            'Jude': [ 'Jd', 'Jud' ],
			'Revelation': [ 'Rev' ],
		};
		this.similarity = function(b1, b2){
			return similarity(b1, b2);

			function similarity(b1, b2) {
		      var longer = b1;
		      var shorter = b2;
		      if (b1.length < b2.length) {
		        longer = b2;
		        shorter = b1;
		      }
		      var longerLength = longer.length;
		      if (longerLength == 0) {
		        return 1.0;
		      }
		      return (longerLength - editDistance(longer, shorter)) / parseFloat(longerLength);
		    }

		    function editDistance(b1, b2) {
		      b1 = b1.toLowerCase();
		      b2 = b2.toLowerCase();

		      var costs = new Array();
		      for (var i = 0; i <= b1.length; i++) {
		        var lastValue = i;
		        for (var j = 0; j <= b2.length; j++) {
		          if (i == 0)
		            costs[j] = j;
		          else {
		            if (j > 0) {
		              var newValue = costs[j - 1];
		              if (b1.charAt(i - 1) != b2.charAt(j - 1))
		                newValue = Math.min(Math.min(newValue, lastValue),
		                  costs[j]) + 1;
		              costs[j - 1] = lastValue;
		              lastValue = newValue;
		            }
		          }
		        }
		        if (i > 0)
		          costs[b2.length] = lastValue;
		      }
		      return costs[b2.length];
		    }
		};
    };
}());
