// This file/class uses Babel + "ES6"

class saPaymentMethod {
    constructor(settings){
        this.settings = settings
        this.isStripe = !!$('.stripe-card').length
        if(this.isStripe){
            this.vendor = new saStripe(settings)
        } else {
            this.vendor = new saCornerstone(settings)
        }
    }
    enableCardInput(){
        this.vendor.enableCardInput()
        if(this.settings.onEnable){
            this.settings.onEnable();
        }
    }

    disableCardInput(){
        this.vendor.disableCardInput()
    }

    createPaymentMethod(){
        this.vendor.disableCardInput()
        this.vendor.createPaymentMethod()
    }
}

class saCornerstone{
    constructor(settings){
        this.settings = settings;
        window.addEventListener('message', (e) => {
            const message = this.getMessage(e)
            if (message.event === 'validChanged') {
                this.validMessage = message.message
            } else if (message.event === 'paymentMethodCreated') {
                this.paymentMethodCreated(message.message)
            } else if (message.event === 'height') {
                this.setHeight(message.message)
            }
        })
    }

    setHeight(height){
        const iframe = $('#billing-card')
        if (!iframe) return
        $(iframe).css("height", height + 'px')
    }

    switchToAch(){
        this.setPaymentType(1)
    }

    switchToCC(){
        this.setPaymentType(0)
    }

    setPaymentType(type){
        this.postMessage('setPaymentType', type)
    }

    disableCardInput(){
        this.postMessage('disabled', true)
    }

    enableCardInput(){
        this.postMessage('disabled', false)
        this.postMessage('reset', { })
    }

    createPaymentMethod(){
        if(!this.validMessage || !this.validMessage.valid){
            var message = this.validMessage && this.validMessage.error ? this.validMessage.error : gettext("Please provide payment information")
            if(this.settings.onCreateError){
                this.settings.onCreateError(message);
            }
            this.enableCardInput()
            sa.toasts.toast(message, sa.toasts.types.error)
            console.error(message);
        } else {
            this.postMessage('createPaymentMethod', { event })
        }
    }

    // paymentMethod: CornerStonePaymentMethod
    paymentMethodCreated(paymentMethod) {
        var paymentType = paymentMethod.paymentType
        if(!paymentType){
            paymentType = paymentMethod.name ? 'ach' : 'cc'
        }
        this.settings.storePaymentMethod({
            payment_id: paymentMethod.id,
            payment_backend: 'cornerstone',
            payment_type: paymentType,
            first_name: paymentMethod.firstName,
            last_name: paymentMethod.lastName,
            check_name: paymentMethod.name,
        })
    }

    // getMessage(event: MessageEvent): Record<'event' | 'message', any> {
    getMessage(event){
        try {
            return JSON.parse(event.data)
        } catch (e) {
            return {
                event: undefined,
                message: '',
            }
        }
    }
    // postMessage(event: string, obj: any) {
    postMessage(event, obj) {
        const iframe = $('#billing-card')[0].contentWindow
        if (!iframe) return
        iframe.postMessage(
            JSON.stringify({
                event,
                message: obj,
            }),'*'
        )
    }
}

class saStripe{
    constructor(settings){
        this.settings = settings;
        this.setupCardInput();
        if(settings.createCustomer){
            this.setupCustomer();
        }
    }
    setupCustomer(){
        $.ajax({
            url: this.$card.data('create-customer-url'),
            data: this.settings.createCustomer,
            success:(data) => {
                this.customerId = data.customer;
                this.$card.data('secret', data.secret);
                this.onCustomerCreated();
            },
        });
    }
    onCustomerCreated(){
        if(this.settings.onCustomerCreated){
            this.settings.onCustomerCreated(this.customerId);
        }
    }
    setupCardInput(){
        this.$card = $('.stripe-card');
        this.stripe = Stripe(this.$card.data('public'), {
            locale: sa.language,
        });
        this.card = this.stripe.elements().create('card');
        this.card.mount('#' + this.$card.prop('id'));
        this.firstName = $('#first-name')
        this.lastName = $('#last-name')
        this.setCardInputStyles();
    }
    createPaymentMethod(){
        var first = this.firstName.val().length >= 3
        var last = this.lastName.val().length >= 3
        this.firstName.toggleClass('error', !first)
        this.lastName.toggleClass('error', !last)

        if(!first || !last){
            var message = !first ? "Please provide a valid first name" : "Please provide a valid last name"
            if(this.settings.onCreateError){
                this.settings.onCreateError(message);
            }
            sa.toasts.toast(message, sa.toasts.types.error)
            return;
        }
        this.stripe.confirmCardSetup(
            this.$card.data('secret'), {
                payment_method: {
                    card: this.card,
                    billing_details: {
                        name: this.firstName.val() + ' ' + this.lastName.val(),
                    },
                },
            }
        ).then((result) => {
            if (result.error) {
                // failure
                var message = result.error.message;
                if(this.settings.onCreateError){
                    this.settings.onCreateError(message);
                }
                sa.toasts.toast(message, sa.toasts.types.error, undefined, result.error.decline_code ? 'Decline code: ' + result.error.decline_code : undefined);
                console.error(result.error);
            } else {
                // success
                this.settings.storePaymentMethod({
                    payment_id: result.setupIntent.payment_method,
                    payment_backend: 'stripe',
                    payment_type: 'cc',
                    first_name: this.firstName.val(),
                    last_name: this.lastName.val(),
                })
            }
        });
    }
    enableCardInput(){
        this.$card.enable();
        this.card.update({disabled: false});
    }
    disableCardInput(){
        this.$card.disable();
        this.card.update({disabled: true});
    }
    setCardInputStyles(){
        var baseStyles = $('.stripe-card-settings .base');
        var placeholderStyles = $('.stripe-card-settings .placeholder');
        var invalidStyles = $('.stripe-card-settings .invalid');
        var completeStyles = $('.stripe-card-settings .complete');
        this.card.update({
            iconStyle: 'solid',
            // https://stripe.com/docs/js/appendix/style
            style: {
                base: {
                    iconColor: placeholderStyles.css('color'),
                    color: baseStyles.css('color'),
                    fontWeight: baseStyles.css('font-weight'),
                    fontFamily: baseStyles.css('font-family'),
                    fontSize: baseStyles.css('font-size'),
                    fontSmoothing: 'antialiased',
                    '::placeholder': {
                        color: placeholderStyles.css('color'),
                    },
                },
                complete: {
                    iconColor: completeStyles.css('color'),
                    color: completeStyles.css('color'),
                },
                invalid: {
                    iconColor: invalidStyles.css('color'),
                    color: invalidStyles.css('color'),
                },
            },
        });
    }
}
