(function() {
    this.saFinder = function(settings) {
        var _this = this;
        this.map = {
            attribution: function() {
                var copyrightSymbol = '&copy; ';
                var osmLink = '<a href="http://www.openstreetmap.org/copyright" target="blank" aria-label="' + gettext("Copyright Open Street Map contributors") + '">OSM</a>';
                var stadiaLink = '<a href="https://stadiamaps.com/" target="blank" aria-label="' + gettext("Copyright Stadia Maps") + '">Stadia Maps</a>';
                var esriLink = '<a href="https://www.esri.com/en-us/about/about-esri" aria-label="' + gettext("Geocoding by ESRI") + '">ESRI</a>';

                var osm = copyrightSymbol + interpolate(gettext('%s contributors'), [osmLink]);
                var stadia = copyrightSymbol + stadiaLink;
                var esri = interpolate(gettext('Geocoding by %s'), [esriLink]);

                return osm + ', ' + stadia + ', ' + esri;
            },
            leafletMap: null,
            bounds: null,
            markers: null,
            myLocation: null,
            userCoords: [],
            userGeoIPFound: false,
            defaultSource: '',
                _myLocationMarker: null,
            _distanceSet: false,
            _minZoom: 4,
            _maxZoom: 18,
            _defaultZoom: 5,
            _countryZoom: 5,
            _userLocationZoom: 8,
            _moveToZoom: 12,
            _moveToZoomMobileModifier: 1,
            _zoomSnap: 1,
            popup: {
                speakerLengthThreshold: 28,
                click: function (popup) {
                    var leftMargin = $('.left-container').width() + _this.options.popupPadding + parseInt($('.left-container').css('left'));
                    var minWindowWidth = leftMargin + 350 + _this.options.popupPadding;
                    if(minWindowWidth < $(window).width()){
                        popup.options.autoPanPaddingTopLeft = [leftMargin, _this.options.popupPadding];
                    } else {
                        popup.options.autoPanPaddingTopLeft = [_this.options.popupPadding, _this.options.popupPadding];
                        _this.page.leftContainer.hide();
                    }
                    popup.update();
                    $(popup._container).attr({
                        'tabindex': '0',
                    });
                    _this.map.popup.last = $(popup);
                    $(popup._container).focus().find('[data-modal-source]').saClick(function(){
                        _this.modal.open($(this));
                    });
                    $(popup._container).find('.leaflet-popup-close-button').attr({
                        'aria-label': gettext("Close popup"),
                        'role': 'button',
                    });
                },
                setDetails: function (c, distance){
                    if(_this.map._distanceSet && _this.options.useMiles){
                        distance = ' • ' + c.distanceMiles;
                    } else if(_this.map._distanceSet){
                        distance = ' • ' + c.distanceKm;
                    } else {
                        distance = '';
                    }
                    var phone = '';
                    if(c.phone && c.phone !== '' && c.speaker.length <= _this.map.popup.speakerLengthThreshold){
                        if(c.speaker){
                            phone = '&nbsp;&nbsp;&#124;&nbsp;&nbsp;' + c.phone;
                        } else {
                            phone = c.phone;
                        }
                    }
                    var modalButton = ' data-modal-source="' + c.sourceid + '"';
                    var mobileCheck = sa.mobile ? modalButton : '';
                    var desktopCheck = sa.mobile ? '' : modalButton;

                    var p = '<div class="popup-container"' + mobileCheck + '>' +
                                '<div class="popup-header">' +
                                    '<img src="' + c.image + '" role="presentation" >' +
                                    '<div class="header-text">' +
                                        '<p class="popup-church-name"><strong>' + c.name + '</strong></p>' +
                                        '<p>' + c.speaker + phone + '</p>' +
                                    '</div>' +
                                '</div>' +
                                '<div class="popup-footer">' +
                                    '<span class="popup-info">' +
                                        c.address + distance +
                                    '</span>' +
                                    '<div tabindex="0" aria-label="' + gettext('Open broadcaster details') + '" ' + desktopCheck + ' class="modal-link" role="button">' + _this.moreIcon; + '</div>' +
                                '</div>' +
                            '</div>';
                    return p;
                },
            },
            initMap: function () {
                var zoomLevel = _this.map._defaultZoom ;
                var centerPoint = _this.map.userCoords;
                var urlParts = window.location.hash.substring(1,window.location.hash.lastIndexOf("z"));
                urlParts = urlParts.split(',');
                var hashCenterPoint = [parseFloat(urlParts[0]),parseFloat(urlParts[1])];
                var hashZoom = parseFloat(urlParts[2]);
                if(_this.map.defaultSource != ''){
                   zoomLevel = _this.map.moveToZoomLevel();
                   centerPoint = _this.getCoordsBySourceID(_this.map.defaultSource);
               } else if (!isNaN(hashZoom) && !isNaN(hashCenterPoint[0]) && !isNaN(hashCenterPoint[1])) {
                    zoomLevel = hashZoom;
                    centerPoint = hashCenterPoint;
                } else if(_this.map.userGeoIPFound){
                    zoomLevel = _this.map._userLocationZoom;
                }
                _this.map.leafletMap = L.map('map', {
                    center: centerPoint,
                    zoomControl: false,
                    keyboard: true,
                    zoom: zoomLevel,
                    zoomSnap: _this.map._zoomSnap,
                    trackResize: true,
                    minZoom: _this.map._minZoom,
                    maxZoom: _this.map._maxZoom,
                    maxBounds: ([
                        [-90, 180], // south west
                        [90, -180] // north east
                    ]),
                });
                _this.map.leafletMap.attributionControl.addAttribution(_this.map.attribution());
                _this.url.set();

                _this.map.leafletMap.addControl(L.control.zoom({
                    position: 'bottomright',
                    keyboard: true
                }));

                var url =
                 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png';
                L.tileLayer(url, {
                    maxZoom: _this.map._maxZoom,
                    id: '',
                    accessToken: '',
                    noWrap: true,
                }).addTo(_this.map.leafletMap);
            },
            getDistance: function (latlng){
                return _this.map.myLocation.distanceTo(latlng);
            },
            isInBounds: function(latlng){
                if(latlng.lat > _this.map.bounds._southWest.lat && latlng.lat < _this.map.bounds._northEast.lat && latlng.lng > _this.map.bounds._southWest.lng && latlng.lng < _this.map.bounds._northEast.lng){
                    return true;
                } else {
                    return false;
                }
            },
            forceMapFocus: function(){
                setTimeout(function(){
                    $('#map').focus();
                }, 0);
            },
            moveToZoomLevel: function(){
                return sa.mobile ? _this.map._moveToZoom : _this.map._moveToZoom + _this.map._moveToZoomMobileModifier;
            },
            moveTo: function (location, zoomOverride){
                if (location.lat && location.lng){
                    // leaflet or geocoder location
                    if(zoomOverride){
                        _this.map.leafletMap.setView(location, zoomOverride);
                    } else {
                        _this.map.leafletMap.setView(location, _this.map.moveToZoomLevel());
                    }

                } else {
                    // marker location
                    if(_this.map.markers.hasLayer(location)){
                        _this.map.markers.zoomToShowLayer(location, function() {
                            _this.map.leafletMap.setView(location.getLatLng());
                            location.openPopup();
                        });
                    } else {
                        _this.map.liveMarkers.zoomToShowLayer(location, function() {
                            _this.map.leafletMap.setView(location.getLatLng());
                            location.openPopup();
                        });
                    }
                }
            },
            moveToMyLocation: function () {
                if(!_this.map.myLocation){
                    _this.map.leafletMap.locate();
                    $('.my-location, .my-location .icon').addClass('searching').removeClass('error');
                } else{
                    _this.map.moveTo(_this.map.myLocation);
                }
            },
            onLocationError: function (e) {
                console.log(e.message);
                $('.my-location, .my-location .icon').removeClass('searching').addClass('error');
                alert(gettext('Something went wrong; please try again. If the problem persists, please contact info@sermonaudio.com.'));
            },
            onLocationFound: function (e) {
                _this.map.myLocation = e.latlng;
                if(!_this.map._myLocationMarker){
                    _this.map._myLocationMarker = L.marker(_this.map.myLocation, {icon: L.divIcon({className: 'centerMarker'})}).addTo(_this.map.leafletMap);
                } else{
                    _this.map._myLocationMarker.setLatLng(_this.map.myLocation).update();
                }
                _this.sortChurchesByDistance();
                _this.map.moveTo(_this.map.myLocation);
                $('.my-location, .my-location .icon').removeClass('searching').addClass('found');
            },
            zoom: function (direction) {
                if(direction){
                    _this.map.leafletMap.zoomIn();
                } else {
                    _this.map.leafletMap.zoomOut();
                }
            },
        };
        this.autocomplete = { /** Autocomplete Variables */
            delay: 300, /** MS before autocomplete fires */
            minGeocoderCharacters: 3, /** required characters before geocoder fires **/
            isBrowsing: false, /** Is true if the user is browsing through the autocomplete results */
            results: [], /** Array of autocomplete results */
            newResults: [], /** Used to store results while they are being generated */
            timeout: null, /** Allows us to cancel the autocomplete if a new key is pressed before the results are returned */
            searching: false,
            searchCount: 0, /** used by the get function to count current results **/
            maxResults: 6, /** maximum total results */
            maxSAResults: 4, /** maximum sermon audio results (maxResults - maxSAResults is the max geocoder results) */
            clear: function (preserveText){
                if(!preserveText){
                    $('#search').val('');
                }
                $('.autocomplete-results ul').html('');
                $('#search').removeClass('open');
                $('.church-list').removeClass('fade');
                _this.autocomplete.isBrowsing = false;
            },
            constructListItem: function (type, title, subtitle){
                var icons = [_this.userIcon, _this.mapMarkerIcon, _this.mapIcon]; // pastor, church, location
                var active = _this.autocomplete.newResults[0] ? '' : ' class="active"';
                var secondary = subtitle ? '<span class="ac-title church-address">' + subtitle + '</span>' : '';
                return '<a href=""' + active + '><li>' +
                                icons[type] +
                                '<div class="detail-container">' +
                                    '<span class="ac-title church-speaker">' + title + '</span>' +
                                    secondary +
                                '</div>' +
                            '</li></a>';
            },
            get: function (){
                _this.autocomplete.searching = true;
                var searchValue = sa.site.regexSanitize($('#search').val());
                if(!searchValue.length){
                    _this.autocomplete.searching = false;
                    return _this.autocomplete.noResults(gettext('Please enter a valid search.'));
                }
                _this.autocomplete.newResults = [];
                _this.autocomplete.searchCount = 0;
                var results = '';
                results += _this.autocomplete.getSAResults(searchValue);
                if(searchValue.length >= _this.autocomplete.minGeocoderCharacters){
                    _this.autocomplete.getGeocoderResults(searchValue, results);
                } else {
                    _this.autocomplete.setAutocompleteResults(results);
                }
            },
            noResults: function(msg){
                var noResultHtml = '<a class="error"><li>' +
                                $('.search-box button').html() +
                                '<div class="detail-container">' +
                                    '<span class="ac-title">' +
                                        gettext('No results found') +
                                    '</span>' +
                                    '<span class="ac-title church-address">' + msg + '</span>' +
                                '</div>' +
                            '</li></a>';
                $('.autocomplete-results ul').html(noResultHtml);
            },
            getSAResults: function(val){
                var tempResults = '';
                var regex = new RegExp(val, 'gi');
                for(var x = 0; x < _this.churches.length; x++){
                    var idChurch = _this.churches[x];
                    if(val === idChurch.sourceid && _this.group.isInGroup(idChurch.group)){
                        var idSpeaker = idChurch.speaker ? idChurch.speaker + ' - ' : '';
                        tempResults += _this.autocomplete.constructListItem(0,idChurch.name,idSpeaker + idChurch.address);
                        _this.autocomplete.searchCount += 1;
                        _this.autocomplete.newResults.push(idChurch.marker);
                        break;
                    }
                }
                for(var i = 0; i < _this.churches.length; i++){
                    if(_this.group.isInGroup(_this.churches[i].group)){
                        var church = _this.churches[i];
                        var name = regex.test(church.name);
                        var speaker = regex.test(church.speaker);
                        var address = regex.test(church.address);
                        if(name || speaker || address){
                            if(speaker){
                                tempResults += _this.autocomplete.constructListItem(1,church.speaker,church.name + ' - ' + church.address);
                            } else {
                                var churchSpeaker = church.speaker ? church.speaker + ' - ' : '';
                                tempResults += _this.autocomplete.constructListItem(0,church.name,churchSpeaker + church.address);
                            }
                            _this.autocomplete.searchCount += 1;
                            _this.autocomplete.newResults.push(church.marker);
                            if(_this.autocomplete.searchCount >= _this.autocomplete.maxSAResults){
                                break;
                            }
                        }
                    }
                }
                return tempResults;
            },
            getGeocoderResults: function (val, otherResults){
                var tempResults = otherResults;
                var currentLocation = _this.map.leafletMap.getCenter();
                var baseURL = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json';
                var searchLayers = 'Address,Postal,Populated Place';
                if(val.match(/\d+/g) !== null){ //contains a number
                    searchLayers += ',address';
                }
                var url =   baseURL +
                            '&maxSuggestions=' + _this.autocomplete.maxResults +
                            '&text=' + val +
                            '&category=' + searchLayers +
                            '&location=' + currentLocation.lng + ',' + currentLocation.lat;

                clearTimeout(_this.autocomplete.timeout); // cancel last autocomplete result (in case it is within the delay period)
                _this.autocomplete.timeout = setTimeout(function(){
                    var lastItem;
                    $.ajax({
                        dataType: 'json',
                        url: url,
                        success: function(data) {
                            for(var y = 0; y < data.suggestions.length; y++){
                                var suggestion = data.suggestions[y];
                                if(suggestion.text === lastItem){
                                    continue;
                                }
                                tempResults += _this.autocomplete.constructListItem(2,suggestion.text);
                                _this.autocomplete.searchCount += 1;
                                lastItem = suggestion.text;

                                var latLng = [0,0];
                                _this.autocomplete.newResults.push({
                                    magicKey: suggestion.magicKey,
                                    text: suggestion.text,
                                });
                                if(_this.autocomplete.searchCount >= _this.autocomplete.maxResults){
                                    break;
                                }
                            }
                            _this.autocomplete.setAutocompleteResults(tempResults);
                        }
                    });
                }, _this.autocomplete.delay);
            },
            AutocompleteResultSubmit: function(result, index){
                if(result[0].innerText !== undefined){
                    var i = _this.autocomplete.results[index];
                    var doAfter = function(goTo){
                        // if the text doesn't include a comma, treat it as a country
                        var isCountry = result[0].innerText.indexOf(',') === -1;
                        if(isCountry){
                            _this.map.moveTo(goTo, _this.map._countryZoom);
                        } else {
                            _this.map.moveTo(goTo);
                        }
                        _this.autocomplete.clear();
                        _this.page.leftContainer.hideIfMobile();
                        _this.map.forceMapFocus();
                    };

                    if(i.magicKey){
                        var url = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates?f=json' +
                        '&magicKey=' + i.magicKey +
                        '&singleLine=' + i.innerText;
                        $.ajax({
                            dataType: 'json',
                            url: url,
                            success: function(data) {
                                var latLng = data.candidates[0].location;
                                doAfter({
                                    lat: latLng.y,
                                    lng: latLng.x,
                                });
                            }
                        });
                    } else {
                        doAfter(i);
                    }
                }
            },
            setAutocompleteResults: function(html){
                if(html === ''){
                    _this.autocomplete.searching = false;
                  return  _this.autocomplete.noResults(gettext('Search may be too specific.'));
                }
                _this.autocomplete.results = _this.autocomplete.newResults;
                $('.autocomplete-results ul').html(html);
                $('.autocomplete-results ul a').each( function( index ) {
                    $(this).on('mouseenter', function( ) {
                        $('.autocomplete-results ul a').each(function(index){
                            $(this).removeClass('active');
                        });
                        $(this).addClass('active');
                        _this.autocomplete.isBrowsing = true;
                    });
                    $(this).on('mouseleave', function( ) {
                        $('.autocomplete-results ul a').each(function(index){
                            $(this).removeClass('active');
                        });
                        $(this).addClass('active');
                        _this.autocomplete.isBrowsing = false;
                    });
                    $(this).saClick(function(event) {
                        event.preventDefault();
                        _this.autocomplete.AutocompleteResultSubmit($(this), index);
                    });
                });
                _this.autocomplete.searching = false;
            },
        };
        this.churches = null;
        this.liveChurches = [];
        this.getChurchIndexByID = function(id){
            for(var i = 0; i < _this.churches.length;i++){
                if(_this.churches[i].sourceid == id){
                    return i;
                }
            }
            return null;
        };
        this.getMarkerBySourceId = function(id){
            return _this.churches[_this.getChurchIndexByID(id)].marker;
        };
        this.getCoordsBySourceID = function(id){
            var church = _this.churches[_this.getChurchIndexByID(id)];
            return [church.lat, church.long];
        };
        this.clusterer = {
            options:{
                //                 4 ,   5 ,   6 ,   7 ,   8 ,  9 ,  10, 11, 12, 13, 14, 15, 16, 17, 18
                maxRadiusArray: [100, 100, 100, 100, 100, 100, 100, 50, 25, 20, 20, 20, 10, 10, 10], // max radius from 4 - 18
                liveMaxRadius: 80,
                liveDisableAtZoom: 8,
            },
            updateLiveStatus: function(id, online){
                var churchIndex = _this.getChurchIndexByID(id);
                if(churchIndex){
                    var church = _this.churches[churchIndex];
                    if(online && !church.webcast){
                        _this.map.markers.removeLayer(church.marker);
                        church.webcast = true;
                        church.marker = _this.clusterer.createMarker(church);
                        _this.map.liveMarkers.addLayer(church.marker);
                    } else if (!online && church.webcast){
                        _this.map.liveMarkers.removeLayer(church.marker);
                        church.webcast = false;
                        church.marker = _this.clusterer.createMarker(church);
                        _this.map.markers.addLayer(church.marker);
                    }
                }
            },
            createMarker: function(c){
                var markerIcon = _this.pinIcon;
                if(c.webcast){
                    markerIcon = _this.livePinIcon;
                }
                var marker = L.marker([c.lat, c.lng], {
                    title: c.name,
                    icon: L.divIcon({
                        className: c.webcast ? 'live-webcast-marker broadcaster-marker' : 'broadcaster-marker',
                        iconSize: [30, 40],
                        iconAnchor: [15, 40],
                        popupAnchor: [0, -50],
                        html: markerIcon
                    }),
                    alt: c.name,
                    riseOnHover: true,
                });
                marker.bindPopup(_this.map.popup.setDetails(c, false), {
                    autoPan: true,
                    autoPanPaddingTopLeft: [_this.options.popupPadding,_this.options.popupPadding],
                    autoPanPaddingBottomRight: [_this.options.popupPadding,_this.options.popupPadding],
                    maxHeight: 100,
                    maxWidth: 350,
                    minWidth: 300,
                });
                return marker;
            },
            cluster: function (init) {
                if(!init){
                    _this.map.leafletMap.removeLayer(_this.map.markers);
                    _this.map.leafletMap.removeLayer(_this.map.liveMarkers);
                }
                _this.map.markers = L.markerClusterGroup({
                    maxClusterRadius: function(zoom){
                        return _this.clusterer.options.maxRadiusArray[zoom - 4];
                    },
                    removeOutsideVisibleBounds: true,
                    zoomToBoundsOnClick: true,
                    polygonOptions: {
                        stroke: false,
                        fillColor: '#152a47',
                        fillOpacity: 0.4,
                        lineCap: 'round',
                        lineJoin: 'round'
                    }
                });
                _this.map.liveMarkers = L.markerClusterGroup({
                    maxClusterRadius: _this.clusterer.options.liveMaxRadius,
                    disableClusteringAtZoom: _this.clusterer.options.liveDisableAtZoom,
                    removeOutsideVisibleBounds: true,
                    zoomToBoundsOnClick: true,
                    polygonOptions: {
                        stroke: false,
                        fillColor: '#2e1547',
                        fillOpacity: 0.4,
                        lineCap: 'round',
                        lineJoin: 'round'
                    },
                    iconCreateFunction: function(cluster) {
                        var liveGroupIcon = '<div class="number">' + cluster.getChildCount() + '</div>' + _this.livePinIcon;
                        return L.divIcon({
                            className: 'live-webcast-marker broadcaster-marker marker-group',
                            iconSize: [30, 40],
                            iconAnchor: [15, 40],
                            popupAnchor: [0, -50],
                            html: liveGroupIcon
                        });
                    }
                });

                for(var i = 0; i < _this.churches.length; i++){
                    var c = _this.churches[i];
                    if(init){
                        _this.group.addToList(c.group);
                    }
                    if(init || _this.group.isInGroup(c.group)){
                        var marker = _this.clusterer.createMarker(c);
                        _this.churches[i].marker = marker;
                        if(c.webcast){
                            _this.map.liveMarkers.addLayer(marker);
                        } else {
                            _this.map.markers.addLayer(marker);
                        }
                    }
                }
                if(init){
                    _this.group.sortList();
                }
                _this.map.leafletMap.addLayer(_this.map.markers);
                _this.map.leafletMap.addLayer(_this.map.liveMarkers);

                _this.map.leafletMap.on('popupopen', function(e) {
                    _this.map.popup.click(e.popup);
                });
                _this.setChurchList();
            }
        };
        this.group = { // Group (denomination) variables
            search: {
                letters: '',
                time: 0
            },
            list: [], // list of groups
            counts: [],
            scrollMargin: 70, // Top offset for our scroll position
            minimum: 2,
            requireMinimum: true,
            selectedIndex: 0, // Our current group
            dropdown: {
                preview: function (index){
                    $('.group-dropdown .selected-title').text(_this.group.list[index]);
                    if(index){
                        $('.group-dropdown').addClass('active');
                    } else {
                        $('.group-dropdown').removeClass('active');
                    }
                },
                set: function (index){
                    if(_this.group.selectedIndex != index){
                        _this.group.selectedIndex = index;
                        _this.clusterer.cluster();
                    }
                    _this.group.dropdown.toggle();
                    $('.church-list').scrollTop(0);
                },
                toggle: function (){
                    if(!$('.group-dropdown').hasClass('open')){
                        $('.group-dropdown').addClass('open');
                        $('.church-list').addClass('fade');
                        sa.aria.modalize.in('.group-dropdown ul', '.group-dropdown .selected-box');
                    } else {
                        $('.group-dropdown').removeClass('open');
                        $('.church-list').removeClass('fade');
                        sa.aria.modalize.out('.group-dropdown ul', '.group-dropdown .selected-box');
                    }
                    _this.group.dropdown.preview(_this.group.selectedIndex);
                },
            },
            addToList: function (group) {
                if(group !== ''){
                    if(_this.group.list.length){
                        for(var i = 0; i < _this.group.list.length; i++){
                            if(_this.group.list[i] == group){
                                _this.group.counts[i] += 1;
                                return;
                            }
                        }
                    }
                    _this.group.list.push(group.charAt(0).toUpperCase() + group.slice(1));
                    _this.group.counts.push(1);
                }
            },
            isInGroup: function (group) {
                return _this.group.selectedIndex === 0 || group.toLowerCase() === _this.group.list[_this.group.selectedIndex].toLowerCase();
            },
            sortList: function (){
                var newGroupList = [];
                for(var i = 0; i < _this.group.list.length; i++){
                    if(_this.group.counts[i] < _this.group.minimum && _this.group.requireMinimum) continue;
                    newGroupList.push(_this.group.list[i]);
                }
                _this.group.list = newGroupList;
                _this.group.list.sort();
                _this.group.list.splice(0, 0, gettext('All Groups'));
                for(var index = 0; index < _this.group.list.length; index++){
                    $('.group-dropdown ul').append('<li tabindex="-1" role="button">' + _this.group.list[index] + '</li>');
                }

                $('.group-dropdown li').each(function(index){
                    $(this).on('mouseenter', function(){
                        $(this).addClass('active');
                    });
                    $(this).on('mouseleave', function(){
                        $(this).removeClass('active');
                    });
                    $(this).saClick( function(){
                        _this.group.dropdown.set(index);
                    });
                    $(this).outlineCallback( function() {
                        _this.helpers.scrollDropdown($('.group-dropdown ul'), $(this));
                    });
                });
            },
        };
        this.modal = { // Modal variables
            currentSource: '',
            openButton: null,
            open: function (button){
                var s = button.data('modal-source');
                if(_this.modal.currentSource != s){
                    _this.modal.openButton = button;
                    $('.modal-contents-container').removeClass('loaded');
                    $('.dynamics-container').html('');
                    sa.audio.clearRemovedPlayers();
                    $.ajax({
                        dataType: 'html',
                        url: '/churchfinder/broadcaster_modal/' + s,
                        success: function(data) {
                            $('.dynamics-container').html(data);
                            $('.modal-contents-container').addClass('loaded');
                            _this.modal.success();
                        }
                    });
                    _this.modal.currentSource = s;
                } else {
                    _this.modal.success();
                }
                _this.modal.toggle();
            },
            success: function(){
                sa.aria.modalize.in('.broadcaster-modal');
                $('.broadcaster-header-info').focus();
            },
            toggle: function () {
                var m = $('.broadcaster-modal');
                if(!m.hasClass('init')){
                    m.addClass('init');
                }
                if(m.hasClass('active')){
                    m.removeClass('active');
                    sa.audio.pauseAll();
                    sa.aria.modalize.out('.broadcaster-modal');
                    _this.modal.openButton.focus();
                } else{
                    m.addClass('active');
                }
            },
            waveformLoaded: function () {
                $('.modal-contents-container').addClass('loaded');
            }
        };
        this.options = {
            maxListItems: 50, // maximum number of items in the church list
            msBetweenKeys: 750, // Delay before the find by key resets
            useMiles: true, // if we're in EU, we'll use km instead of mi for distances
            popupPadding: 11,
        };
        this.page = { // Page variables
            sizing: {
                timeout: null,
            },
            leftContainer: {
                toggle: function(){
                    if(!$('.left-container').hasClass('closed')){
                        _this.page.leftContainer.hide();
                    } else {
                        _this.page.leftContainer.show();
                    }
                },
                hideIfMobile: function(){
                    if($(window).width() <= sa.site.page.breakpoints[1] || sa.mobile){
                        _this.page.leftContainer.hide();
                    }
                },
                show: function(){
                    var leftContainer = $('.left-container');
                    leftContainer.removeClass('closed');
                    leftContainer.css('left', '');
                    sa.aria.modalize.showElements('.left-container');
                },
                hide: function(){
                    var leftContainer = $('.left-container');
                    leftContainer.addClass('closed');
                    leftContainer.css('left', -leftContainer.outerWidth());
                    sa.aria.modalize.hideElements('.left-container', '.left-container_close-button');
                }
            },
            resized: function (){
                clearTimeout(_this.page.sizing.timeout);
                _this.page.sizing.timeout = setTimeout(function(){
                    if($('.left-container').hasClass('closed')){
                        $('.left-container').css('left', -$('.left-container').outerWidth());
                    }
                }, sa.site.page.sizing.delay);
            },
        };
        this.helpers = {
            scrollDropdown: function (dropdownElement, target) {
                var pos = dropdownElement.scrollTop() + target.position().top;
                if(pos > _this.group.scrollMargin){
                    pos -= _this.group.scrollMargin;
                } else {
                    pos = 0;
                }
                dropdownElement.scrollTop(pos);
            },
            toDecimalPlaces: function (num,places){
                var divider = places > 0 ? Math.pow(10,places) : 1;
                return Math.round((num + 0.00001) * divider) / divider;
            },
            toKilometers: function (dist){
                var k = sa.toDecimals(dist/1000,2);
                if(k >= 1000){
                    k = Math.round(k);
                }
                return k + ' km';
            },
            toMiles: function (dist) {
                var m = sa.toDecimals(dist *  0.000621371,2);
                if(m >= 100000){
                    m = sa.toDecimals(m/1000,0) + 'k';
                } else if(m >= 1000){
                    m = sa.toDecimals(m/1000,1) + 'k';
                } else if(m >= 100){
                    m = Math.round(m);
                }
                return m + ' mi';
            },
        };
        this.arrowMovement = function (k,listToNavigate){
            var focused;
            listToNavigate.each(function(){
                if($(this).hasClass('active')){
                    focused = $(this);
                    return false;
                }
            });
            if(focused){
                focused.removeClass('active');
                if(k == 'down' && focused.next()[0]){
                    focused = focused.next();
                } else if (k == 'up' && focused.prev()[0]){
                    focused = focused.prev();
                }
                focused.addClass('active');
            }
        };
        this.arrowPressed = function (key){
            if($('#search').hasClass('entered') && $('#search').hasClass('open') && (key == 'up' || key == 'down')){
                _this.arrowMovement(key,$('.autocomplete-results ul a'));
            }
            if($('.group-dropdown').hasClass('open') && (key == 'up' || key == 'down')){
                _this.arrowMovement(key,$('.group-dropdown ul li'));
                $('.group-dropdown ul li.active').focus().removeClass('outline');
                _this.helpers.scrollDropdown($('.group-dropdown ul'), $('.group-dropdown ul li:focus'));
            }
        };
        this.checkSearch = function (t){
            if(!$(t).hasClass('entered') && $(t).val() !== ''){
                $(t).addClass('entered open');
            } else if ($(t).hasClass('entered') && $(t).val() === '') {
                $(t).removeClass('entered open');
            }
        };
        this.escPressed = function (){
            if($('.broadcaster-modal').hasClass('active')){
                _this.modal.toggle();
            }
            if($('#map').is(':focus')){
                _this.page.leftContainer.toggle();
            }
            if($('.group-dropdown').hasClass('open')){
                _this.group.dropdown.toggle();
            }
            if($('#search').hasClass('open')){
                _this.autocomplete.clear(true);
            } else if ($('#search').is(':focus')){
                _this.map.forceMapFocus();
            }
        };
        this.findGroupByLetter = function (key){
            if($.now() - _this.group.search.time > _this.options.msBetweenKeys){
                _this.group.search.letters = key.toLowerCase();
            } else {
                _this.group.search.letters += key.toLowerCase();
            }
            _this.group.search.time = $.now();
            var i = 0;
            var index;
            while (i < _this.group.list.length){
                if(_this.group.list[i].slice(0,_this.group.search.letters.length).toLowerCase() == _this.group.search.letters){
                    index = i;
                    break;
                }
                i++;
            }
            if(index !== undefined){
                $('.group-dropdown ul li').each(function(index){
                    $(this).removeClass('active');
                });
                $('.group-dropdown ul li').eq(i).addClass('active');
                _this.helpers.scrollDropdown($('.group-dropdown ul'), $('.group-dropdown ul li').eq(index));
            }
        };
        this.init = function () {
            $(window).focus(function() {
                $('#map').focus();
            });

            _this.churches = settings.churches;
            _this.map.userCoords = settings.coords;
            _this.map.userGeoIPFound = settings.geoip;
            _this.map.defaultSource = settings.initSource;

            _this.pinIcon = $('.map-icon-container [data-map-icon="pin"]').html();
            _this.mapIcon = $('.map-icon-container [data-map-icon="map"]').html();
            _this.moreIcon = $('.map-icon-container [data-map-icon="more"]').html();
            _this.mapMarkerIcon = $('.map-icon-container [data-map-icon="map-marker"]').html();
            _this.userIcon = $('.map-icon-container [data-map-icon="user"]').html();
            _this.livePinIcon = $('.map-icon-container [data-map-icon="pin-live"]').html();
            $('.map-icon-container').remove();

            _this.map.initMap();
            _this.sortChurchesAlphabetically();
            $('.my-location').insertBefore('.leaflet-control-zoom-in');
            if(!sa.mobile){
                $('.map-help').insertAfter('.leaflet-control-zoom');
            }

            _this.setupListeners();
            _this.clusterer.cluster(true);
            _this.page.leftContainer.hideIfMobile();
            // SET INIT FOCUS
            $('#search').focus();
            setTimeout( function (){
                $('.left-container').addClass('transitions');
            }, 0);
            if(_this.map.defaultSource != ''){
                _this.map.moveTo(_this.getMarkerBySourceId(_this.map.defaultSource));
            }
            $('.leaflet-marker-icon, .leaflet-control-zoom-in, .leaflet-control-zoom-out, .my-location').each(function(){
                $(this).attr('tabindex', '-1');
            });
        };
        this.searchSubmit = function (){
            if(!_this.autocomplete.searching){
                $('.autocomplete-results ul a').each(function(index){
                    if($(this).hasClass('active') && !$(this).hasClass('error')){
                        _this.autocomplete.AutocompleteResultSubmit($(this), index);
                    }
                });
            }
        };
        this.setChurchList = function (){
            _this.map.bounds = _this.map.leafletMap.getBounds();
            var listSet = '';
            var listLocations = [];
            var added = 0;
            var ignoreViewport = false;
            if(_this.group.selectedIndex !== 0) {
                ignoreViewport = true;
            }
            for(var i = 0; i < _this.churches.length; i++){
                var c = _this.churches[i];
                var inBounds =  _this.map.isInBounds({lat: c.lat, lng: c.lng});
                var inGroup = _this.group.isInGroup(c.group);
                if (inGroup && (ignoreViewport || inBounds )){
                    var speaker =  c.speaker !== '' ? '<span class="church-speaker">' + c.speaker + ' • </span>' : '';
                    var dist = '';
                    if(_this.map._distanceSet){
                        if(_this.options.useMiles){
                            dist =  '<span class="church-distance">' +
                                        c.distanceMiles +
                                    '</span>';
                        } else {
                            dist =  '<span class="church-distance">' +
                                        c.distanceKm +
                                    '</span>';
                        }
                    }
                    var li ='<a href="" role="button" class="church-list-item">' +
                                '<li>' +
                                    '<img src="' + c.image + '">' +
                                    '<div class="detail-container">' +
                                        '<div class="church-header">' +
                                            '<span class="church-title">' +
                                                c.name +
                                            '</span>' +
                                            dist +
                                        '</div>' +
                                        '<div class="church-footer">' +
                                            speaker +
                                            '<span class="church-address">' +
                                                c.address +
                                            '</span>' +
                                        '</div>' +
                                    '</div>' +
                                '</li>' +
                            '</a>';
                    listSet += li;
                    listLocations.push(c.marker);
                    added ++;
                    if(added >= _this.options.maxListItems){
                        break;
                    }
                }
            }
            if(listSet !== ''){
                if(!$('.church-list').hasClass('active')) $('.church-list').addClass('active');
            } else {
                $('.church-list').removeClass('active');
            }
            $('.church-list ul').html(listSet);
            $('.church-list ul a').each( function( index ) {
                $(this).saClick( function( event ) {
                    event.preventDefault();
                    _this.map.moveTo(listLocations[index]);
                    _this.page.leftContainer.hideIfMobile();
                });
            });

            sa.aria.outline.update();
        };
        this.sortChurchesByDistance = function () {
            _this.map._distanceSet = true;
            var distances = [];
            var distancesSorted = [];
            var alreadyChecked = [];
            var sortedArray = [];
            for(var i = 0; i < _this.churches.length; i++){
                var d = _this.map.getDistance([_this.churches[i].lat,_this.churches[i].lng]);
                distances.push(d);
                distancesSorted.push(d);
            }

            distancesSorted.sort(function(a, b){return a-b;});
            var previous = 0;
            for(var l = 0; l < distancesSorted.length; l++){
                previous = distancesSorted[l];
                for(var s = 0; s < distances.length; s++){
                    if(distancesSorted[l] == distances[s] && alreadyChecked.indexOf(s) == -1){
                        var c = _this.churches[s];
                        c.distance = distances[s];
                        c.distanceKm = _this.helpers.toKilometers(distances[s]);
                        c.distanceMiles = _this.helpers.toMiles(distances[s]);
                        c.marker.setPopupContent(_this.map.popup.setDetails(c, true));
                        sortedArray.push(c);
                        alreadyChecked.push(s);
                        break;
                    }
                }
            }
            _this.churches = sortedArray;
        };
        this.sortChurchesAlphabetically = function () {
            _this.churches.sort(function(a,b){
                var regex = /'|"|\(|\)|-|_|\+|=|\.|!|@|#|,|\$|%|\^|:|\/|\?|;|\*|\&|\\|\||<|>/ig;
                var nameA = a.name.replace(regex, "");
                var nameB = b.name.replace(regex, "");
                if (nameA < nameB)
                    return -1;
                if (nameA > nameB)
                    return 1;
                return 0;
            });
        };
        this.setupListeners = function (){
            $(window).resize(_this.page.resized);
            $('#search').on('input focus', function (){
                _this.checkSearch(this);
                $('#search').addClass('open');
                if($(this).val() !== ''){
                    _this.autocomplete.get();
                    if(!$('.church-list').hasClass('fade')){
                        $('.church-list').addClass('fade');
                    }
                } else {
                    $('.church-list').removeClass('fade');
                    $('.autocomplete-results ul').html('');
                }
            });
            $('#search_form').on('focusout', function () {
                var parent = $(this);
                setTimeout( function (){
                    if(parent.has($(document.activeElement)).length === 0 && !_this.autocomplete.isBrowsing){
                        _this.autocomplete.clear(true);
                    }
                }, 0);
            });
            _this.map.leafletMap.on('locationfound', function(event){
                _this.map.onLocationFound(event);
            });
            _this.map.leafletMap.on('locationerror', _this.map.onLocationError);
            _this.map.leafletMap.on('moveend', function(){
                _this.setChurchList();
                _this.url.set();
            });

            $('.my-location-indicator').saClick( function(event){
                event.preventDefault();
                _this.map.leafletMap.doubleClickZoom.disable();
                setTimeout( function (){
                    _this.map.leafletMap.doubleClickZoom.enable();
                }, 10);
            });
            $('.help-button').saClick( function(event){
                event.preventDefault();
                _this.map.leafletMap.doubleClickZoom.disable();
                _this.toggleHelpUI();
                setTimeout( function (){
                    _this.map.leafletMap.doubleClickZoom.enable();
                }, 10);
            });
            $('.broadcaster-modal .modal-background, .broadcaster-modal .modal-close-button').saClick( function(event){
                _this.modal.toggle();
            });
            $('.left-container_close-button').saClick( function(event){
                event.preventDefault();
                _this.page.leftContainer.toggle();
            } );
            $('.my-location').saClick( function(){
                _this.map.moveToMyLocation();
            });

            $('.group-dropdown .selected-box').saClick( function (event){
                _this.group.dropdown.set(_this.group.selectedIndex);
            });
            $('.group-dropdown').on('mouseleave', function (){
                if($('.group-dropdown').hasClass('open')){
                    _this.group.dropdown.set(_this.group.selectedIndex);
                }
            });
            $('.dropdown_close-button').saClick( function(){
                _this.group.dropdown.set(0);
            });
            $( '#search_form' ).submit(function( e ) {
                e.preventDefault();
                _this.searchSubmit();
            });

            $(document).on('keypress', function(e) {
                if($('.group-dropdown').hasClass('open')){
                    _this.findGroupByLetter(String.fromCharCode(e.which));
                    if(e.which == sa.keycodes.enter){
                        $('.group-dropdown li').each(function(index){
                            if($(this).hasClass('active')){
                                _this.group.dropdown.set(index);
                            }
                        });
                    }
                }
            });
            $(document).on('keydown', function(event) {
                switch(event.which) {
                    case sa.keycodes.tab:
                    case sa.keycodes.enter:
                    case sa.keycodes.plus:
                    case sa.keycodes.minus:
                    return;

                    case sa.keycodes.esc:
                        _this.escPressed();
                    break;

                    case sa.keycodes.arrowUp: // up
                        _this.arrowPressed('up');
                    break;

                    case sa.keycodes.arrowDown: // down
                        _this.arrowPressed('down');
                    break;

                    default:
                        if($('#map').is(":focus")){
                            $('#search').focus();
                        }
                    return; // exit this handler for other keys
                }
                event.preventDefault(); // prevent the default action (scroll / move caret)
            });

            sa.webcasts.updateIntervalSpeed(30);
            sa.webcasts.subscribeToWebcastCheck( function(){
                var liveLastInterval = _this.liveChurches;
                var live = sa.webcasts.live;
                _this.liveChurches = live;

                var goneOffline = liveLastInterval.diff(live);
                var goneOnline = live.diff(liveLastInterval);
                for(var x = 0; x < goneOffline.length; x++){
                    _this.clusterer.updateLiveStatus(goneOffline[x], false);
                }
                for(var y = 0; y < goneOnline.length; y++){
                    _this.clusterer.updateLiveStatus(goneOnline[y], true);
                }
            });
        };
        this.url = {
            subString: '',
            timeout: null,
            set: function(){
                clearTimeout(_this.url.timeout);
                _this.url.timeout = setTimeout(function(){
                    var mapCenter = _this.map.leafletMap.getCenter();
                    var zoom = _this.map.leafletMap.getZoom();
                    var lat = _this.helpers.toDecimalPlaces(mapCenter.lat, 4);
                    var lng = _this.helpers.toDecimalPlaces(mapCenter.lng, 4);
                    var newUrl = '#' + lat + ',' + lng + ',' + zoom +'z';
                    if (typeof (history.replaceState) != "undefined" && newUrl != _this.url.subString) {
                        history.replaceState(null, null, newUrl);
                        _this.url.subString = newUrl;
                    }
                }, 1000);

            }
        };
        this.toggleHelpUI = function () {
            if(!$('body').hasClass('help')){
                $('body').addClass('help');
                $('body').removeClass('help-closed');
            } else {
                $('body').removeClass('help');
                $('body').addClass('help-closed');
            }
        };
        _this.init();
    };
}());
