(function() {
    this.saBeginTimeSlider = function(callback) {
        var _this = this;
        this.mousedown = false;
        this.duration = 0;
        this.time = 0;
        this.notifyTimeout = null;
        this.notifyTimeoutSpeed = 200;
        this.updateDuration = function(newDuration){
            _this.duration = newDuration;
            if(_this.time >= _this.duration){
                _this.time = newDuration - 1;
            }
            _this.update(false);
        };
        this.mouseMove = function(event) {
            var offset = $('.time-slider .bar-container').offset();
            var x = sa.getPointerEvent(event).pageX;
            var width = $('.time-slider .bar-container').width();
            var percent = (x - offset.left) / width * 100;
            if (percent < 0) {
                percent = 0;
            } else if (percent > 100) {
                percent = 100;
            }
            _this.time = parseInt(percent / 100 * _this.duration);
            _this.update(true);
        };
        this.update = function(notify) {
            var percent = (_this.time / _this.duration) * 100;
            var time = sa.toHHMMSS(_this.time);

            $('.time-slider .bar').css('left', percent + '%');
            $('.time-slider .handle').css('left', 'calc(' + percent + '% - ' + $('.time-slider .handle').width() / 2 + 'px)');
            $('.time-slider .time span').text(time);
            if(notify){
                clearTimeout(_this.notifyTimeout);
                _this.notifyTimeout = setTimeout(function(){
                    _this.notify();
                }, _this.notifyTimeoutSpeed);
            }
        };
        this.notify = function () {
            // defined by the script that instantiated this one.
        };
        this.nudge = function(amount) {
            var value = parseInt(_this.time) + amount;
            if (value >= 0 && value < _this.duration) {
                _this.time = value;
            }
            _this.update(true);
        };
        this.init = function () {
            _this.notify = callback;
            $('.time-slider .nudge.left').on("click", function() {
                _this.nudge(-1);
            });
            $('.time-slider .nudge.right').on("click", function() {
                _this.nudge(1);
            });

            if(sa.mobile){
                $('.time-slider .handle').on('touchstart', function() {
                    _this.mousedown = true;
                });
                $('.time-slider .handle').on('touchend', function() {
                    _this.mousedown = false;
                });
                $(document).on('touchmove', function(event) {
                    if (_this.mousedown) {
                        _this.mouseMove(event);
                    }
                });
            } else {
                $('.time-slider .handle').on('touchstart mousedown', function() {
                    _this.mousedown = true;
                });
                $(document).on('mouseup', function() {
                    _this.mousedown = false;
                });
                $(document).on('mousemove', function(event) {
                    if (_this.mousedown) {
                        _this.mouseMove(event);
                    }
                });
            }

            _this.update(false);
        };
        _this.init();
    };
}());
