(function() {
    this.saEmbedEditor = function(settings) {
        var _this = this;
        this.embedCode = '';
        this.previewCode = '';
        this.active = 'video';
        this.audioDuration = 0;
        this.videoDuration = 0;
        this.customTime = 0;
        this.options = {
            useTime: false,
            autoplay: false,
            tinyAudio: false,
            darkTheme: false,
            browserBorder: true,
            browserStyle: 'classic',
            browserHeader: false,
            browserFooter: false,
            browserFilters: false,
            browserBackground: true,
            browserRounded: false,
            browserSermonBorders: true,
            browserRemoveInlinePlayer: false,
            webcastButton: false,
            webcastButtonBorderless: false,
            webcastButtonRemoveBackground: false,
            webcastButtonRounded: false,
            webcastButtonViewCount: false,
            webcastButtonPeakCount: false,
            webcastButtonTiny: false,
        };

        this.audioSize = 'auto';
        this.videoSize = 'auto';
        this.browserWidth = 'auto';
        this.browserHeight = '560';
        this.browserFilterCategory = '';
        this.browserFilterSelection = '';
        this.browserPageSize = 25;
        this.sermonType = 'single';
        this.size = { x: 1280, y: 720 };
        this.audioHeight = { default: 150, tiny: 60};
        this.toggleEmbedType = function(event){
            var clicked = $(this);
            $('.embed-editor main').removeClass('audio video webcast browser');
            $('.embed-editor .tab-nav button').removeClass('active');
            _this.active = clicked[0].id;
            clicked.addClass('active');

            $('.embed-editor main').addClass(_this.active);
            _this.updateSliderDuration();
            _this.generate();
            sa.aria.outline.update();
        };
        this.updateSliderDuration = function () {
            if(_this.active == 'video'){
                _this.slider.updateDuration(_this.videoDuration);
            } else if (_this.active == 'audio'){
                _this.slider.updateDuration(_this.audioDuration);
            }
        };
        this.webcastButton = function(){
            return _this.active == 'webcast' && _this.options.webcastButton;
        };
        this.webcast = function(){
            return _this.active == 'webcast' && !_this.options.webcastButton;
        };
        this.to16x9 = function(width, height){
            if (width) return (width / 16) * 9;
            else if (height) return (height / 9) * 16;
            else return null;
        };
        this.getAutoBrowserHeight = function(){
            /*
            IN THE FUTURE, WE MAY WANT THESE VALUES TO NOT BE HARD CODED -
            THIS IS PROVING CHALLENGING HOWEVER, SO I'M GOING TO HARD CODE THEM
            IF WE WANT TO TRY AGAIN, HERE IS A HEAD START:

            var browser = $('#preview iframe').contents().find('.embed-browser');
            // remove padding to keep inconsistant padding from ruining everything
            browser.find('.sermon, .sort-filter-container').css('border-width', 0);
            var sermonSize = browser.find('.sermon:nth-child(1)').outerHeight();
            var fullSermonSize = browser.find('.sermon').prop('scrollHeight');
            var header = browser.find('.header').outerHeight();
            var footer = browser.find('.footer').outerHeight();
            var filters = browser.find('.sort-filter-container').outerHeight();

            // re-add padding to make it consistant
            if(_this.options.browserSermonBorders || compact){
                sermonSize += 2;
                fullSermonSize += 2;
                if(compact || !_this.options.browserBackground){
                    finalSize -= 2;
                }
            }

            finalSize = Math.ceil(finalSize);
            */
            var finalSize = 0;
            var compactSermonSize = 61;
            var classicSermonSize = 66;
            var sermonSize = classicSermonSize;
            var additionalInlinePlayerSize = 85;
            var header = 90;
            var footer = 54;
            var filters = 60;
            var sermonContPadding = 15;
            var hasHeader = !_this.options.browserHeader;
            var hasFooter = !_this.options.browserFooter;
            var hasFilters = !_this.options.browserFilters;
            var hasBorder = _this.options.browserBorder;
            var hasBackground = _this.options.browserBackground;
            var hasInline = !_this.options.browserRemoveInlinePlayer;
            var compact = _this.options.browserStyle == 'compact';

            var poweredBySize = compact ? 35 : 20;
            var showingPoweredBy = !hasHeader && settings.removeExternalLinks;

            if(showingPoweredBy){
                finalSize += poweredBySize;
            }

            if(hasInline){
                finalSize += additionalInlinePlayerSize;
            }
            if(!compact) {
                finalSize += sermonContPadding;
                if(!hasFilters || !hasBackground){
                    finalSize += sermonContPadding;
                }
            } else {
                sermonSize = compactSermonSize;
            }
            finalSize += sermonSize * _this.browserPageSize;

            if(hasFilters) finalSize += filters;
            if(hasFooter)  finalSize += footer;
            if(hasHeader)  finalSize += header;

            // deal with borders
            if(!compact){
                finalSize -= 1;
            } else {
                finalSize += 2;
                if(!hasFooter){
                    finalSize += 1;
                }
                if(!hasFilters){
                    finalSize -= 1;
                }
                if(!hasHeader){
                    finalSize -= 1;
                }
            }
            if(!hasBorder)  finalSize -= 2;

            $('#browser-calculated-height').val(finalSize);
            return finalSize;
        };
        this.normalizeSizes = function (event) {
            if(_this.active == 'video' || _this.webcast()){
                var width = $('#video-custom-size .width');
                var height = $('#video-custom-size .height');
                if($(event.target).hasClass('width')){
                    // base on width
                    height.val(_this.to16x9(width.val(), null));
                } else {
                    //base on height
                    width.val(_this.to16x9(null, height.val()));
                }
                if(width.val() < 200){
                    width.val(200);
                    height.val(_this.to16x9(200, null));
                }
            } else if(_this.active == 'browser'){
                if($('#browser-custom-width .width').val() < 400){
                    $('#browser-custom-width .width').val(400);
                }
                if($('#browser-custom-height .height').val() < 360){
                    $('#browser-custom-height .height').val(360);
                }
            }
        };
        this.normalizePageSize = function(num){
            if(num > 50){
                num = 50;
            } else if (num < 1) {
                num = 1;
            }
            $('#browser-sermon-count').val(num);
            _this.browserPageSize = num;
        };
        this.getXY = function () {
            var x, y, size;
            if(_this.active == 'video' || _this.webcast() ){
                size = _this.videoSize;
                switch (size) {
                    case 'auto':
                        x = '100%';  y = '100%';
                        break;
                    case 'custom':
                        x = $('#video-custom-size .width').val();
                        y = $('#video-custom-size .height').val();
                        break;
                    default:
                        x = parseInt(size);
                        y = Math.round(x / 1.77777777);
                }
            } else if (_this.webcastButton()) {
                if(_this.options.webcastButtonTiny){
                    x = 40;
                } else {
                    x = 200;
                }
                y = 40;
            } else if (_this.active == 'audio'){
                size = _this.audioSize;
                switch (size) {
                    case 'auto':
                        x = '100%';
                        break;
                    case 'custom':
                        x = Math.round($('#audio-custom-size .width').val());
                        if(x < 150){
                            $('#audio-custom-size .width').val(150);
                            x = 150;
                        }
                        break;
                    default:
                        x = parseInt(size);
                }
                if(_this.options.tinyAudio){
                    y = _this.audioHeight.tiny;
                } else {
                    y = _this.audioHeight.default;
                }
            } else if (_this.active == 'browser'){
                var height = _this.browserHeight;
                var width = _this.browserWidth;
                switch (width) {
                    case 'auto':
                        x = '100%';
                        break;
                    case 'custom':
                        x = Math.round($('#browser-custom-width .width').val());
                        break;
                    default:
                        x = parseInt(width);
                }
                switch (height) {
                    case 'auto':
                        y = _this.getAutoBrowserHeight();
                        break;
                    case 'custom':
                        y = Math.round($('#browser-custom-height .height').val());
                        break;
                    default:
                        y = parseInt(height);
                }
            }

            _this.size.x = x;
            _this.size.y = y;
        };
        this.updateOptions = function(){
            _this.videoSize = sa.dropdownValue('video-size-dropdown');
            if (_this.videoSize == 'custom' && (_this.active == 'video' || _this.active == 'webcast')){
                $('#video-custom-size').addClass('visible');
            } else {
                $('#video-custom-size').removeClass('visible');
            }

            _this.audioSize = sa.dropdownValue('audio-width-dropdown');
            if(_this.audioSize == 'custom' && _this.active == 'audio'){
                $('#audio-custom-size').addClass('visible');
            } else {
                $('#audio-custom-size').removeClass('visible');
            }

            _this.browserHeight = sa.dropdownValue('browser-height-dropdown');
            if(_this.active == 'browser'){
                if(_this.browserHeight == 'custom'){
                    $('#browser-custom-height').addClass('visible');
                } else {
                    $('#browser-custom-height').removeClass('visible');
                }
                if(_this.browserHeight == 'auto'){
                    $('#browser-height-audio-description').addClass('visible');
                } else {
                    $('#browser-height-audio-description').removeClass('visible');
                }
            }
            _this.browserWidth = sa.dropdownValue('browser-width-dropdown');
            if(_this.browserWidth == 'custom' && _this.active == 'browser'){
                $('#browser-custom-width').addClass('visible');
            } else {
                $('#browser-custom-width').removeClass('visible');
            }

            $('.embed-editor main').removeClass('classic compact');
            if(_this.active == 'browser'){
                _this.options.browserStyle = $('input[name=layout-style]:checked').val();
                $('.embed-editor main').addClass($('input[name=layout-style]:checked').val());
            }

            _this.sermonType = sa.dropdownValue('sermon-dropdown');
            if(_this.sermonType != 'single'){
                $('#customTimeCheckbox').prop('disabled', true);
            } else {
                $('#customTimeCheckbox').prop('disabled', false);
            }

            $('#autoplayCheckbox').prop('disabled', _this.webcastButton());
            var webcastSizeEditable = _this.active == 'webcast' && !_this.webcastButton();
            var videoSizeEditable = webcastSizeEditable || _this.active == 'video'
            $('#video-size-dropdown').toggleDisability(videoSizeEditable);

            if(_this.options.browserStyle == 'compact' && _this.options.browserFilters && _this.options.browserFooter && _this.options.browserHeader){
                $('#roundCornersCheckbox').prop('disabled', true);
            }  else {
                $('#roundCornersCheckbox').prop('disabled', false);
            }

            _this.browserFilterCategory = sa.dropdownValue('browser-sermons-filter-dropdown-category');
            _this.browserFilterSelection = sa.dropdownValue('browser-sermons-filter-dropdown-'  + _this.browserFilterCategory);
            _this.recentSermonCategory = sa.dropdownValue('recent-sermons-dropdown-category');
            _this.recentSermonSelection = sa.dropdownValue('recent-sermons-dropdown-'  + _this.recentSermonCategory);
        };
        this.getSubUrl = function (){
            var newUrl;
            if(_this.active == 'browser'){
                newUrl = 'browser/broadcaster/' + settings.broadcaster;
                if(_this.browserFilterCategory && _this.browserFilterSelection && _this.browserFilterCategory !== "all" ){
                    newUrl += '/' + _this.browserFilterCategory + '/' + _this.browserFilterSelection;
                }
                return newUrl + '/';
            } else if (_this.webcastButton()){
                return 'button/l/' + settings.broadcaster + '/';
            } else {
                newUrl = 'player/';
                if(_this.active == 'webcast'){
                    return newUrl + 'l/' + settings.broadcaster + '/';
                } else {
                    newUrl += _this.active == 'audio' ? 'a/' : 'v/';
                    if(_this.sermonType == 'single'){
                        return newUrl + _this.sermon + '/';
                    } else{
                        newUrl += 'broadcaster/' + settings.broadcaster + '/';
                    }
                    if(_this.sermonType){
                        if(_this.sermonType == 'speaker'){
                            return newUrl + 'speaker/' + _this.speaker + '/';
                        } else if (_this.sermonType == 'event'){
                            return newUrl + 'event/' + _this.event + '/';
                        } else if (_this.sermonType == 'series'){
                            return newUrl + 'series/' + _this.series + '/';
                        } else {
                            return newUrl;
                        }
                    } else {
                        if (_this.recentSermonCategory == 'highlighted' || _this.recentSermonCategory == 'broadcaster') return newUrl;
                        if (_this.recentSermonCategory && _this.recentSermonSelection ){
                            newUrl += _this.recentSermonCategory + '/' + _this.recentSermonSelection + '/';
                        }
                        return newUrl;
                    }
                }
            }
        };
        this.getOptions = function () {
            var querySet = false;
            var options = '';
            if(_this.active == 'browser'){
                options += '?sort=' + sa.dropdownValue('browser-sermons-sort-dropdown');
                if(_this.browserPageSize)                   options += '&page_size=' + _this.browserPageSize;
                if(_this.browserHeight == 'auto')           options += '&scrolls=false';
                if(_this.options.browserStyle != 'classic') options += '&style=' + _this.options.browserStyle;
                if(_this.options.browserHeader)             options += '&header=false';
                if(_this.options.browserFooter)             options += '&footer=false';
                if(_this.options.browserFilters)            options += '&filters=false';
                if(_this.options.darkTheme)                 options += '&dark=true';
                if(!_this.options.browserBackground &&
                    _this.options.browserStyle == 'classic')options += '&background=false';
                if(_this.options.browserRemoveInlinePlayer) options += '&remove_player=true';
                if(_this.options.browserRounded)            options += '&rounded=true';
                if(!_this.options.browserSermonBorders)     options += '&sermon_borders=false';
                if(!_this.options.browserBorder)            options += '&external_borders=false';
                if(settings.removeExternalLinks)            options += '&remove_external_links=true';
            } else if(_this.webcastButton()){
                options += '?color=' + sa.dropdownValue('webcast-button-color');
                var reg = !_this.options.webcastButtonTiny;
                if(_this.options.webcastButtonBorderless)       options += '&borderless=true';
                if(_this.options.webcastButtonRemoveBackground) options += '&removeBackground=true';
                if(_this.options.webcastButtonRounded)          options += '&rounded=true';
                if(_this.options.webcastButtonViewCount && reg) options += '&viewCount=true';
                if(_this.options.webcastButtonPeakCount && reg) options += '&peakCount=true';
            } else {
                if(_this.options.useTime && _this.sermonType == 'single' && _this.active != 'webcast'){
                    options += '?time=' + _this.slider.time;
                }
                if(_this.options.autoplay){
                    options += options != '' ? '&autoplay=true' : '?autoplay=true';
                }
                if(_this.active == 'audio'){
                    if(_this.options.darkTheme) options += options != '' ? '&dark=true' : '?dark=true';
                    if(_this.options.tinyAudio) options += options != '' ? '&mini=true' : '?mini=true';
                }
                if (_this.recentSermonCategory == 'highlighted' || _this.sermonType == 'highlighted'){
                    options += options != '' ? '&highlighted=true' : '?highlighted=true';
                }
            }
            if(settings.solo){
                options += options.length != '' ? '&solo=true' : '?solo=true'
            }
            return options;
        };
        this.iframeAssembly = function(url, size , style, fullscreen, autoplay){
            var html = '<iframe tabindex="-1" width="' + size.x + '" height="' + size.y + '" src="' + url + '" ';
            if(style && style !== '') html +=  'style="' + style + '" ';
            if(fullscreen) html += 'allowfullscreen ';
            if(autoplay) html += 'allow="autoplay" ';
            html += 'frameborder="0" scrolling="no"></iframe>';
            return html;
        };
        this.assembleCode = function () {
            var newEmbed, newPreview, iframe;
            var url = settings.baseUrl + '/' + _this.getSubUrl() + _this.getOptions();

            $('#preview').css('max-width', 'none');

            if(_this.active == 'video' || _this.webcast()){
                iframe = _this.iframeAssembly(url, {x: '100%', y: '100%'}, 'position:absolute;left:0;top:0', true, _this.options.autoplay);
                newPreview = '<div style="position:relative;width:100%;height:0;padding-bottom:56.25%;margin: auto;">' + iframe + '</iframe></div>';
                if(_this.videoSize == 'auto'){
                    newEmbed = '<div style="position:relative;width:100%;height:0;padding-bottom:56.25%">' + iframe + '</div>';
                } else {
                    newEmbed = _this.iframeAssembly(url, {x: _this.size.x, y: _this.size.y}, false , true, _this.options.autoplay);
                    $('#preview').css('max-width', _this.size.x);
                }
            } else if (_this.webcastButton()){
                newEmbed = _this.iframeAssembly(url, {x:  _this.size.x, y: _this.size.y});
                newPreview = _this.iframeAssembly(url, {x:  _this.size.x, y: 100});
            } else if (_this.active == 'audio'){
                newEmbed = _this.iframeAssembly(url, {x: _this.size.x, y: _this.size.y}, 'min-width: 150px;', false, _this.options.autoplay);
                newPreview = _this.iframeAssembly(url, {x: _this.size.x, y: _this.size.y}, 'min-width: 150px; max-width: 100%; margin: 4rem auto 0;', false, _this.options.autoplay);
            } else if (_this.active == 'browser'){
                var x = _this.size.x;
                var min = '';
                if(x == '100%'){
                    x = '1';
                    min = 'min-width: 100%; ';
                }
                newEmbed = _this.iframeAssembly(url, {x: x, y: _this.size.y}, min + 'max-width: 100%; ', false, true);
                newPreview = _this.iframeAssembly(url, {x: x, y: _this.size.y}, min + 'margin: auto; max-width: 100%;', false, true);
            }

            return {embed: newEmbed, preview: newPreview};
        };
        this.generate = function(){
            $('.copy-code-button').removeClass('success');
            _this.updateOptions();
            _this.getXY();

            var code = _this.assembleCode();
            _this.embedCode = code.embed;
            _this.previewCode = code.preview;

            _this.updateInput();
            _this.updatePreview();
        };
        this.updateInput = function(){
            $('#embed-code').val(_this.embedCode);
        };
        this.updatePreview = function(){
            $('#preview').html(_this.previewCode);
        };
        this.init = function () {
            _this.audioDuration = settings.audioDuration ? settings.audioDuration : undefined;
            _this.videoDuration = settings.videoDuration ? settings.videoDuration : undefined;

            if(settings.sermon){
                _this.sermon = settings.sermon;
                _this.event = settings.event;
                _this.series = settings.series;
                _this.speaker = settings.speaker;
            }

            _this.active = $('.embed-editor .tab-nav button.active')[0].id;
            $('.embed-editor main').addClass(_this.active);
            $('.embed-editor main .tab-nav button').saClick(this.toggleEmbedType);
            $('input[name=layout-style]').saCheckbox(function(){
                _this.generate();
            });
            $('#customTimeCheckbox').saCheckbox(function(){
                _this.options.useTime = $(this).prop('checked');
                _this.generate();
            });
            $('#autoplayCheckbox').saCheckbox(function(){
                _this.options.autoplay = $(this).prop('checked');
                _this.generate();
            });
            $('#tinyAudioCheckbox').saCheckbox(function(){
                _this.options.tinyAudio = $(this).prop('checked');
                _this.generate();
            });
            $('#darkThemeCheckbox').saCheckbox(function(){
                _this.options.darkTheme = $(this).prop('checked');
                _this.generate();
            });
            $('#headerCheckbox').saCheckbox(function(){
                _this.options.browserHeader = !$(this).prop('checked');
                _this.generate();
            });
            $('#footerCheckbox').saCheckbox(function(){
                _this.options.browserFooter = !$(this).prop('checked');
                _this.generate();
            });
            $('#removeBackgroundCheckbox').saCheckbox(function(){
                _this.options.browserBackground = !$(this).prop('checked');
                _this.generate();
            });
            $('#roundCornersCheckbox').saCheckbox(function(){
                _this.options.browserRounded = $(this).prop('checked');
                _this.generate();
            });
            $('#removeSermonBordersCheckbox').saCheckbox(function(){
                _this.options.browserSermonBorders = !$(this).prop('checked');
                _this.generate();
            });
            $('#inlineCheckbox').saCheckbox(function(){
                _this.options.browserRemoveInlinePlayer = $(this).prop('checked');
                _this.generate();
            });
            $('#filterCheckbox').saCheckbox(function(){
                _this.options.browserFilters = !$(this).prop('checked');
                _this.generate();
            });
            $('#borderCheckbox').saCheckbox(function(){
                _this.options.browserBorder = !$(this).prop('checked');
                _this.generate();
            });
            $('#webcastButtonCheckbox').saCheckbox(function(){
                _this.options.webcastButton = $(this).prop('checked');
                _this.generate();
            });
            $('#borderlessCheckbox').saCheckbox(function(){
                _this.options.webcastButtonBorderless = $(this).prop('checked');
                _this.generate();
            });
            $('#removeBackgroundCheckbox').saCheckbox(function(){
                _this.options.webcastButtonRemoveBackground = $(this).prop('checked');
                _this.generate();
            });
            $('#roundedCheckbox').saCheckbox(function(){
                _this.options.webcastButtonRounded = $(this).prop('checked');
                _this.generate();
            });
            $('#viewCheckbox').saCheckbox(function(){
                _this.options.webcastButtonViewCount = $(this).prop('checked');
                _this.generate();
            });
            $('#peakCheckbox').saCheckbox(function(){
                _this.options.webcastButtonPeakCount = $(this).prop('checked');
                _this.generate();
            });
            $('#tinyButtonCheckbox').saCheckbox(function(){
                _this.options.webcastButtonTiny = $(this).prop('checked');
                if(_this.options.webcastButtonTiny){
                    $('#peakCheckbox, #viewCheckbox').prop('disabled', true);
                } else {
                    $('#peakCheckbox, #viewCheckbox').prop('disabled', false);
                }
                _this.generate();
            });
            $('#browser-sermon-count').on('change blur', function(){
                _this.normalizePageSize($(this).prop('value'));
                _this.generate();
            });
            $('select').on('change', _this.generate);
            $('.size-input').on('change blur', function (event) {
                _this.normalizeSizes(event);
                _this.generate();
            });
            _this.slider = new saBeginTimeSlider(_this.generate);
            _this.updateSliderDuration();
            _this.generate();
        };
        this.init();
    };
}());
